import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    CatchUpItems,
    DebitCard,
    DebitCardOptions,
    Homeless,
    SupplementalQuestions,
    TouchpointAnswers,
    TouchpointQuestions
} from 'types';

import Complete from './components/Complete';
import Ellipse from './components/Ellipse';
import FirstCatchUp from './components/FirstCatchUp';
import Summary from './components/Summary';
import Welcome from './components/Welcome';

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        catchupContainer: {
            position: 'absolute',
            top: 0,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            background: '#F5F2FF',
            height: '100%',
            overflow: 'hidden',
            width: '100%'
        },
        closeButton: {
            position: 'absolute',
            top: 24,
            right: 24,
            zIndex: 100,
            fontWeight: 400,
            fontSize: '2.75rem',
            color: '#999DFF'
        },
        title: {
            fontSize: '1.5rem',
            fontWeight: 500,
            marginBottom: 10,
            textAlign: 'center'
        },
        description: {
            fontSize: '1rem',
            textAlign: 'center'
        }
    })
);

type CatchupProps = {
    catchUpItemIndex: number;
    debitCardOptions: DebitCardOptions[];
    debitInfo: DebitCard;
    firstTimeCatchUp: boolean;
    getApiErrorResponse: { message: string } | {};
    holder: Homeless;
    isEditingGoalAction: boolean;
    items: CatchUpItems[];
    maxBonusAmount: number;
    quickCatchUp: boolean;
    remainingBonusAmount: number;
    setAddStatus: (value: boolean) => void;
    setCatchUpItemIndex: (index: number) => void;
    setQuickCatchUp: (value: boolean) => void;
    setShowCatchUp: (showCatchUp: boolean) => void;
    supplementalQuestions: SupplementalQuestions[];
    touchpointAnswers: TouchpointAnswers[];
    touchpointQuestions: TouchpointQuestions[];
    updateGoalActionStatus: (
        id: number,
        status: 'cancelled' | 'completed'
    ) => void;
    updateHomelessInfoField: (field: string, value: string) => void;
    updateProfileCompletedItems: (type: string) => void;
    userID: number;
};

type HistoryStateType = {
    from?: string;
    id?: number;
};

const Catchup: React.FC<CatchupProps> = ({
    catchUpItemIndex,
    debitCardOptions,
    debitInfo,
    getApiErrorResponse,
    firstTimeCatchUp,
    holder,
    isEditingGoalAction,
    items,
    maxBonusAmount,
    quickCatchUp,
    remainingBonusAmount,
    setAddStatus,
    setCatchUpItemIndex,
    setQuickCatchUp,
    setShowCatchUp,
    supplementalQuestions,
    touchpointAnswers,
    touchpointQuestions,
    updateGoalActionStatus,
    updateHomelessInfoField,
    updateProfileCompletedItems,
    userID
}) => {
    const classes = useStyles();
    const firstTimeCatchUpRef = useRef<boolean>(firstTimeCatchUp);
    const history = useHistory();
    const itemLengthRef = useRef<number>(items.length);

    const { name } = holder;

    const [firstTimeCatchUpComplete, setFirstTimeCatchUpComplete] = useState(
        firstTimeCatchUp ? false : true
    );
    const [firstTimeCatchUpState, setFirstTimeCatchUpState] = useState(
        firstTimeCatchUpRef.current
    );
    const [step, setStep] = useState(quickCatchUp ? 1 : 0);

    const handleCloseClick = () => {
        setCatchUpItemIndex(0);
        setQuickCatchUp(false);
        setShowCatchUp(false);
    };

    const handleProceedClick = () => {
        if (step !== 1) {
            setStep((prevStep) => prevStep + 1);
        }
    };

    const renderWelcome = () => {
        if (step === 0 && items.length !== 0) {
            return <Welcome name={name} />;
        }
    };

    const summaryProps = {
        catchUpItemIndex,
        catchUpItemsLength: itemLengthRef.current,
        debitInfo,
        debitCardOptions,
        firstTimeCatchUp: firstTimeCatchUpState,
        firstTimeCatchUpComplete,
        getApiErrorResponse,
        holder,
        isEditingGoalAction,
        items,
        maxBonusAmount,
        name,
        quickCatchUp,
        remainingBonusAmount,
        setAddStatus,
        setCatchUpItemIndex,
        step,
        supplementalQuestions,
        touchpointAnswers,
        touchpointQuestions,
        updateGoalActionStatus,
        updateHomelessInfoField,
        updateProfileCompletedItems
    };

    useEffect(() => {
        if (
            history.location.state &&
            (history.location.state as HistoryStateType).from === 'catchUp'
        ) {
            setStep(1);
        }
    }, [history.location.state]);

    useEffect(() => {
        if (firstTimeCatchUpComplete) {
            firstTimeCatchUpRef.current = false;
            setFirstTimeCatchUpState(false);
        }
    }, [firstTimeCatchUpComplete, items]);

    return (
        <Fade in timeout={quickCatchUp ? 0 : 2000}>
            <div
                className={classes.catchupContainer}
                onClick={handleProceedClick}
            >
                {items.length !== 0 && (
                    <Button
                        className={classes.closeButton}
                        onClick={handleCloseClick}
                    >
                        <i className="far fa-times" />
                    </Button>
                )}
                <Ellipse position="top-left" />
                <Ellipse position="bottom-right" />
                {renderWelcome()}
                {step === 1 &&
                    firstTimeCatchUpState &&
                    !firstTimeCatchUpComplete &&
                    items.length > 0 && (
                        <FirstCatchUp
                            itemLengthRef={itemLengthRef.current}
                            setFirstTimeCatchUpComplete={
                                setFirstTimeCatchUpComplete
                            }
                            userID={userID}
                            {...summaryProps}
                        />
                    )}
                {step === 1 &&
                    !firstTimeCatchUpState &&
                    firstTimeCatchUpComplete &&
                    ((!quickCatchUp && items.length > 0) ||
                        (quickCatchUp &&
                            catchUpItemIndex !== items.length)) && (
                        <Summary {...summaryProps} />
                    )}
                {/* {firstTimeCatchUp && step === 1 && (
                    <FirstCatchUp
                        handleProceedClick={() => {
                            setStep(3);
                        }}
                    />
                )} */}
                {((!quickCatchUp && items.length === 0) ||
                    (quickCatchUp && catchUpItemIndex === items.length)) && (
                    <Complete
                        setCatchUpItemIndex={setCatchUpItemIndex}
                        setShowCatchUp={setShowCatchUp}
                        setQuickCatchUp={setQuickCatchUp}
                    />
                )}
            </div>
        </Fade>
    );
};

export default Catchup;
