import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { User } from 'types';

import { setIntakeDeploymentId } from 'redux/actions/intake';
import { closePopUp } from 'redux/store/slices/eligibilitySlice';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        '& button': {
            padding: '16px 48px',
            width: '100%'
        }
    },
    deploymentButton: {
        border: '1px solid #E3E3FA',
        borderRadius: '16px',
        boxShadow: '0px 8px 16px 4px #0000000D',
        fontSize: '18px',
        padding: '20px',
        justifyContent: 'space-between',
        textTransform: 'none',
        '& i': {
            color: '#999DFF'
        },
        '& span': {
            color: '#1F2152',
            fontWeight: 600
        }
    },
    deploymentSelectorContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        margin: '24px 0'
    },
    heading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
        paddingBottom: '24px',
        '& h2': {
            color: '#062D7A',
            margin: 0
        },
        '& span': {
            color: '#5A6A81',
            fontWeight: 300,
            maxWidth: '75%',
            textAlign: 'center'
        }
    }
}));

type DeploymentSelectorProps = {
    setConfirmedDeployment: (deployment: null | number) => void;
    user: User;
};

const DeploymentSelector: React.FC<DeploymentSelectorProps> = ({
    setConfirmedDeployment,
    user
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { partner } = user;

    const deployments =
        partner?.deployments?.filter(
            (deployment) => !deployment.is_grandfathered
        ) ?? [];

    const handleDeploymentClick = (
        deploymentId: number,
        requiresEligibilityCheck: boolean
    ) => {
        dispatch(setIntakeDeploymentId(deploymentId));
        setConfirmedDeployment(deploymentId);

        if (!requiresEligibilityCheck) {
            dispatch(closePopUp());
            history.push('/intake');
        }
    };

    return (
        <div className={classes.deploymentSelectorContainer}>
            <div className={classes.heading}>
                <h2>Select a deployment</h2>
                <span>
                    Please select the insurance or provider group that this
                    patient's Membership will be a part of
                </span>
            </div>
            {deployments.map((deployment) => (
                <Button
                    key={deployment.id}
                    className={classes.deploymentButton}
                    onClick={() =>
                        handleDeploymentClick(
                            deployment.id,
                            deployment.is_eligibility_check_required
                        )
                    }
                >
                    <span>{deployment.name}</span>
                    <i className="far fa-arrow-right" />
                </Button>
            ))}
        </div>
    );
};

export default DeploymentSelector;
