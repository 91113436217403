import { useState } from 'react';
import store from 'store2';

import CompleteFirstCatchUp from './CompleteFirstCatchUp';
import Summary from './Summary';

import {
    DebitCard,
    DebitCardOptions,
    Homeless,
    CatchUpItems,
    SupplementalQuestions,
    TouchpointAnswers,
    TouchpointQuestions
} from 'types';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        firstCatchUpContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '24px',
            height: '100%',
            padding: '0 20px'
        }
    })
);

type FirstCatchUpProps = {
    catchUpItemIndex: number;
    catchUpItemsLength: number;
    debitInfo: DebitCard;
    debitCardOptions: DebitCardOptions[];
    firstTimeCatchUp: boolean;
    firstTimeCatchUpComplete: boolean;
    getApiErrorResponse: { message?: string };
    holder: Homeless;
    isEditingGoalAction: boolean;
    itemLengthRef: number;
    items: CatchUpItems[];
    maxBonusAmount: number;
    name: string;
    quickCatchUp: boolean;
    remainingBonusAmount: number;
    setAddStatus: (value: boolean) => void;
    setCatchUpItemIndex: (index: number) => void;
    setFirstTimeCatchUpComplete: (value: boolean) => void;
    supplementalQuestions: SupplementalQuestions[];
    touchpointAnswers: TouchpointAnswers[];
    touchpointQuestions: TouchpointQuestions[];
    updateGoalActionStatus: (id: number, status: 'completed') => void;
    updateHomelessInfoField: (field: string, value: string) => void;
    updateProfileCompletedItems: (type: string) => void;
    userID: number;
};

const FirstCatchUp: React.FC<FirstCatchUpProps> = ({
    catchUpItemIndex,
    debitInfo,
    debitCardOptions,
    firstTimeCatchUp,
    firstTimeCatchUpComplete,
    getApiErrorResponse,
    holder,
    isEditingGoalAction,
    itemLengthRef,
    items,
    maxBonusAmount,
    name,
    quickCatchUp,
    remainingBonusAmount,
    setAddStatus,
    setCatchUpItemIndex,
    setFirstTimeCatchUpComplete,
    supplementalQuestions,
    touchpointAnswers,
    touchpointQuestions,
    updateGoalActionStatus,
    updateHomelessInfoField,
    updateProfileCompletedItems,
    userID
}) => {
    const classes = useStyles();

    const [step, setStep] = useState(0);

    const handleProceedClick = () => {
        setFirstTimeCatchUpComplete(true);

        store.set('renderFirstTimeCatchUp', {
            ...store.get('renderFirstTimeCatchUp'),
            [userID]: false
        });
    };

    const summaryProps = {
        catchUpItemIndex,
        catchUpItemsLength: itemLengthRef,
        debitInfo,
        debitCardOptions,
        firstTimeCatchUp,
        firstTimeCatchUpComplete,
        getApiErrorResponse,
        holder,
        isEditingGoalAction,
        items,
        maxBonusAmount,
        name,
        quickCatchUp,
        remainingBonusAmount,
        setAddStatus,
        setCatchUpItemIndex,
        step,
        setFirstCatchUpStep: setStep,
        supplementalQuestions,
        touchpointAnswers,
        touchpointQuestions,
        updateGoalActionStatus,
        updateHomelessInfoField,
        updateProfileCompletedItems
    };

    return (
        <div className={classes.firstCatchUpContainer}>
            {step === 0 && <Summary {...summaryProps} />}
            {step === 1 && items.length > 1 && (
                <CompleteFirstCatchUp handleProceedClick={handleProceedClick} />
            )}
        </div>
    );
};

export default FirstCatchUp;