import { useHistory } from 'react-router-dom';

import { Homeless } from 'types';

import { CTAButton3 } from 'components/_shared/buttons';

import useIntakeAutofill from 'hooks/useIntakeAutofill';

import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        marginTop: '40px'
    }
}));

type PopUpButtonsProps = {
    eligibleMember: boolean;
    foundMember: boolean;
    handleCloseClick: () => void;
    member: Homeless | null;
    memberID: number | undefined;
    setMember: ({
        memberInfo,
        memberFound
    }: {
        memberInfo: Homeless | null;
        memberFound: boolean | null;
    }) => void;
};

const PopUpButtons: React.FC<PopUpButtonsProps> = ({
    eligibleMember,
    foundMember,
    handleCloseClick,
    member,
    memberID,
    setMember
}) => {
    const classes = useStyles();
    const history = useHistory();

    const { autofillIntake } = useIntakeAutofill();

    const handleStartRegistration = () => {
        autofillIntake(member);

        handleCloseClick();
        history.push(`/intake`);
    };

    const handleViewProfile = () => {
        handleCloseClick();

        history.push(`/holders/${memberID}`);
    };

    const handleEditInfoClick = () => {
        setMember({ memberInfo: null, memberFound: null });
    };

    return (
        <div className={classes.buttonContainer}>
            {eligibleMember && (
                <CTAButton3 onClick={handleStartRegistration}>
                    Start Registration
                </CTAButton3>
            )}
            {foundMember && (
                <CTAButton3 onClick={handleViewProfile}>
                    View Profile
                </CTAButton3>
            )}
            {!eligibleMember && !foundMember && (
                <CTAButton3
                    border
                    iconFront
                    iconName="pencil"
                    invert
                    onClick={handleEditInfoClick}
                >
                    Edit Information
                </CTAButton3>
            )}
            {eligibleMember || foundMember ? (
                <CTAButton3 invert onClick={handleCloseClick}>
                    Close
                </CTAButton3>
            ) : (
                <CTAButton3
                    border
                    iconFront
                    iconName="envelope"
                    invert
                    onClick={handleCloseClick}
                >
                    <Link
                        color="#999DFF"
                        href="mailto:support@samaritan.city"
                        rel="noreferrer"
                        target="_blank"
                        underline="none"
                    >
                        Contact Support
                    </Link>
                </CTAButton3>
            )}
        </div>
    );
};

export default PopUpButtons;
