import classNames from 'classnames';

import { Button } from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';

const useStyles = makeStyles(
    (theme: {
        colors: {
            LightGrey03: string;
            LightGrey05: string;
            LightRed01: string;
            OffWhite01: string;
            Purple01: string;
        };
    }) =>
        createStyles({
            button: {
                // color
                color: 'white',
                background:
                    'linear-gradient(0deg, #9897E3, #9897E3), linear-gradient(270deg, rgba(184, 194, 255, 0.75) 0%, rgba(184, 194, 255, 0) 100%)',

                // font
                fontSize: '1rem',
                fontWeight: 700,
                textTransform: 'none',

                // box
                padding: '10px 2.5rem',
                borderRadius: 1000,
                margin: 1,
                minWidth: 150,

                // psudoclasses
                '&:hover': {
                    backgroundColor: theme.colors.Purple01
                },

                // selectors
                '&.Mui-disabled': {
                    backgroundColor: theme.colors.LightGrey03,
                    color: 'white'
                }
            },
            border: {
                border: '1px solid #7378E8',
                color: '#7378E8'
            },
            cancel: {
                color: theme.colors.LightRed01 + ' !important'
            },
            disabled: {
                background: theme.colors.LightGrey03,
                color: 'white'
            },
            tight: {
                padding: '5px 1rem',
                minWidth: 'unset'
            },
            tighter: {
                padding: '0px 8px',
                width: 'fit-content',
                minWidth: 'unset'
            },
            icon: {
                margin: '0px 16px'
            },
            invert: {
                background: 'none',
                backgroundColor: theme.colors.OffWhite01,
                color: '#999DFF',
                '&:hover': {
                    backgroundColor: theme.colors.LightGrey05
                }
            }
        })
);

type CTAButton3Props = {
    arrow?: boolean;
    border?: boolean;
    cancel?: boolean;
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    iconFront?: boolean;
    iconName?: string;
    invert?: boolean;
    onClick?: () => void;
    tight?: boolean;
    tighter?: boolean;
    type?: 'button' | 'submit' | 'reset';
};

export default function CTAButton3({
    arrow,
    border,
    cancel,
    children,
    className,
    disabled,
    iconFront,
    iconName,
    invert,
    tight,
    tighter,
    ...rest
}: CTAButton3Props): JSX.Element {
    const theme = useTheme();
    const styles = useStyles(theme);

    if (arrow) {
        iconName = 'chevron-right';
    }

    const buttonClassName = classNames(
        styles.button,
        border && styles.border,
        cancel && styles.cancel,
        disabled && styles.disabled,
        invert && styles.invert,
        tight && styles.tight,
        tighter && styles.tighter,
        className
    );

    const iconClassName = classNames('far', `fa-${iconName}`, styles.icon);

    return (
        <Button className={buttonClassName} {...rest} disabled={disabled}>
            {iconFront && iconName && <i className={iconClassName} />}
            <>{children}</>
            {!iconFront && iconName && <i className={iconClassName} />}
        </Button>
    );
}
