import { useEffect, useState, useRef } from 'react';

import { NewGoalActions } from 'types';

import ActionStepForm from 'components/intake/Actions/components/ActionStepForm';
import ActionCardOverlay from './ActionCardOverlay';
import Modal from 'components/_shared/Modal';

import { useActionTemplate, useRemoteCopy } from 'hooks';

import { Box, Skeleton } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        formContainer: {
            position: 'relative',
            width: '100%'
        },
        loadingContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '500px'
        }
    })
);

type AddProps = {
    addFailed: boolean;
    cancelAction: (value: boolean) => void;
    failedAction: NewGoalActions | null;
    from: string;
    holder: {
        id: number;
    };
    orgID: number;
    profileCompletionType?: null | string;
    remainingBonusAmount: number;
    setAddFailed: (value: boolean) => void;
    setFailedAction: (value: any) => void;
    setShowActionOverlay: (value: boolean) => void;
    showActionOverlay: boolean;
    userID: number;
};

const AddActionStep: React.FC<AddProps> = ({
    addFailed,
    cancelAction,
    failedAction,
    from,
    holder,
    orgID,
    remainingBonusAmount,
    setAddFailed,
    setFailedAction,
    setShowActionOverlay,
    showActionOverlay
}) => {
    const addRef = useRef<HTMLDivElement>(null);
    const actionStepFormRef = useRef<{ handleAddForProfile: () => void }>(null);
    const classes = useStyles();
    const suggestionsRefetchCount = useRef(0);
    const suggestions = useRemoteCopy('action_step_suggestions');
    const templatesRefetchCount = useRef(0);

    const { id: holder_id } = holder;

    const {
        isError: templateActionsError,
        isLoaded: templateActionsLoaded,
        refetch: templateRefetch,
        templateActions
    } = useActionTemplate(orgID);

    const [formComplete, setFormComplete] = useState(false);
    const [isErrored, setIsErrored] = useState(false);

    const ActionStepSkeleton = () => {
        return (
            <Box>
                <Skeleton
                    variant="rounded"
                    width="100%"
                    height={!templateActions?.length ? 264 : 500}
                />
            </Box>
        );
    };

    const callChildAddForProfile = () => {
        if (actionStepFormRef.current) {
            actionStepFormRef.current.handleAddForProfile();
        }
    };

    const handleCancelClick = () => {
        cancelAction(false);
    };

    useEffect(() => {
        if (formComplete) {
            callChildAddForProfile();
        }
    }, [formComplete]);

    useEffect(() => {
        if (addRef && addRef.current && !templateActions) {
            addRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'center'
            });
        }
    }, [addRef, suggestions, templateActions]);

    useEffect(() => {
        if (suggestions.isError && suggestionsRefetchCount.current < 5) {
            suggestionsRefetchCount.current++;
            suggestions.refetch();
        }

        if (suggestionsRefetchCount.current >= 5) {
            setIsErrored(true);
        }
    }, [suggestions]);

    useEffect(() => {
        if (templateActionsError && templatesRefetchCount.current < 5) {
            templatesRefetchCount.current++;
            templateRefetch();
        }

        if (templatesRefetchCount.current >= 5) {
            setIsErrored(true);
        }
    }, [templateActionsError, templateActionsLoaded, templateRefetch]);

    if (isErrored) {
        return (
            <Modal
                open={isErrored}
                onClose={() => {
                    setIsErrored(false);
                }}
            >
                <div style={{ padding: 20 }}>
                    <div className="font-lg">Something has gone wrong.</div>
                    <div>
                        Please refresh and try again. We apologize for this
                        inconvenience. If this error persists{' '}
                        <a href="mailto:support@samaritan.com">
                            contact support
                        </a>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <>
            {addFailed && failedAction && showActionOverlay && (
                <ActionCardOverlay
                    setAddFailed={setAddFailed}
                    setFailedAction={setFailedAction}
                    setShowActionOverlay={setShowActionOverlay}
                >
                    <i className="fas fa-exclamation-circle" />
                    <span>{failedAction.error}</span>
                </ActionCardOverlay>
            )}
            <div className={classes.formContainer} ref={addRef}>
                {!suggestions.isLoaded ||
                !suggestions ||
                !templateActionsLoaded ? (
                    <ActionStepSkeleton />
                ) : (
                    <ActionStepForm
                        actionSuggestions={suggestions.remoteCopy}
                        addFailed={addFailed}
                        failedAction={failedAction}
                        from={from}
                        handleCancelClick={handleCancelClick}
                        holderID={holder_id}
                        ref={actionStepFormRef}
                        remainingBonusAmount={remainingBonusAmount}
                        setFormComplete={setFormComplete}
                        setShowForm={cancelAction}
                        showCancel={!formComplete}
                        templateActions={templateActions}
                    />
                )}
            </div>
        </>
    );
};

export default AddActionStep;
