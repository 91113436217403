import { createSlice } from '@reduxjs/toolkit';

interface EligibilityState {
    isPopUpOpen: boolean;
}

const initialState: EligibilityState = {
    isPopUpOpen: false
};

const eligibilitySlice = createSlice({
    name: 'eligibility',
    initialState,
    reducers: {
        openPopUp: (state) => {
            state.isPopUpOpen = true;
        },
        closePopUp: (state) => {
            state.isPopUpOpen = false;
        }
    }
});

export const { openPopUp, closePopUp } = eligibilitySlice.actions;

export default eligibilitySlice.reducer;
