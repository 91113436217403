import Fade from '@mui/material/Fade';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        welcomeContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '24px',
            height: '100%',
            width: '96%'
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '72%',
            padding: '0 20px'
        },
        description: {
            color: '#1F2152',
            fontSize: '2rem',
            textAlign: 'center',
            maxWidth: '800px',
            wordWrap: 'break-word'
        },
        proceedMessage: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            color: '#5A6A81',
            fontSize: '20px',
            fontWeight: 200,
            marginTop: '80px'
        },
        title: {
            color: '#1F2152',
            fontSize: '2.5rem',
            fontWeight: 700,
            marginBottom: 10
        },
        wave: {
            fontSize: '2.5rem'
        }
    })
);

type WelcomeProps = {
    name: string;
};

const Welcome: React.FC<WelcomeProps> = ({ name }) => {
    const classes = useStyles();

    return (
        <div className={classes.welcomeContainer}>
            <span className={classes.wave}>👋</span>
            <div className={classes.body}>
                <span className={classes.title}>Welcome to Catch Up!</span>
                <Fade in timeout={5000}>
                    <span className={classes.description}>
                        To save you some time, we gathered actions you might
                        want to take with {name} today
                    </span>
                </Fade>
                <div className={classes.proceedMessage}>
                    <i className="fal fa-chevron-left" />
                    Click anywhere to proceed
                    <i className="fal fa-chevron-right" />
                </div>
            </div>
        </div>
    );
};

export default Welcome;
