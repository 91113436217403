import { Homeless, TargetListMember } from 'types';

import { formatDate } from 'util/index';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    detailsBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        backgroundColor: '#F2F2FD',
        border: '1px solid #E3E3FA',
        borderRadius: '8px',
        marginTop: '16px',
        padding: '24px'
    },
    detailField: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px'
    },
    detailLabel: {
        color: '#5A6A81',
        fontWeight: 300,
        fontSize: '16px'
    },
    detailValue: {
        color: '#1F2152',
        fontWeight: 700,
        fontSize: '18px'
    }
}));

type MemberDetailsProps = {
    member?: Homeless | TargetListMember | null;
    details?: ({
        label: string;
        value: string;
    } | null)[];
};

const getMemberName = (pii_first_name?: string, pii_last_name?: string) => {
    if (pii_first_name || pii_last_name) {
        return `${pii_first_name || ''} ${pii_last_name || ''}`.trim();
    }

    return '-';
};

const getMembershipStatus = (profile_stage: string | undefined) => {
    switch (profile_stage) {
        case 'EXITED':
            return 'CLOSED';
        case 'LIVE':
            return 'ACTIVE';
        case 'NEEDS_REVIEW':
            return 'ACTIVE';
        case 'PENDING':
            return 'PENDING';
        case 'UNSUCCESSFUL_PENDING':
            return 'CLOSED';
        default:
            return '-';
    }
};

const MemberDetails: React.FC<MemberDetailsProps> = ({ details, member }) => {
    const classes = useStyles();

    const renderDetails = () => {
        if (!details && !member) {
            return null;
        }

        if (!details && member) {
            const birthday = formatDate(member.date_of_birth);
            const memberName = getMemberName(
                member.pii_first_name,
                member.pii_last_name
            );
            const memberStatus = getMembershipStatus(member.profile_stage);

            return [
                { label: 'Full Name', value: memberName },
                { label: 'Date of Birth', value: birthday },
                {
                    label: 'Insurance ID',
                    value: member.pii_member_id?.length
                        ? member.pii_member_id
                        : '-'
                },
                // { label: 'Enrolled On', value: 'TEMP' },
                { label: 'Current Membership Status', value: memberStatus }
            ];
        }

        if (details && !member) {
            return details;
        }

        return null;
    };

    const detailsToRender = renderDetails();

    return (
        <div className={classes.detailsBody}>
            {detailsToRender?.map((detail, index) =>
                detail ? (
                    <div className={classes.detailField} key={index}>
                        <span className={classes.detailLabel}>
                            {detail.label}
                        </span>
                        <span className={classes.detailValue}>
                            {detail.value}
                        </span>
                    </div>
                ) : null
            )}
        </div>
    );
};

export default MemberDetails;
