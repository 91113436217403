import Fade from '@mui/material/Fade';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        firstCatchUpContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '24px',
            height: '100%',
            padding: '0 20px'
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 20px'
        },
        description: {
            color: '#1F2152',
            fontSize: '2rem',
            textAlign: 'center',
            '@media (max-width: 600px)': {
                fontSize: '1.5rem'
            }
        },
        proceedMessage: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            color: '#5A6A81',
            fontSize: '20px',
            fontWeight: 200,
            paddingTop: '80px'
        }
    })
);

type CompleteFirstCatchUpProps = {
    handleProceedClick: () => void;
};

const CompleteFirstCatchUp: React.FC<CompleteFirstCatchUpProps> = ({
    handleProceedClick
}) => {
    const classes = useStyles();

    return (
        <Fade in timeout={2000}>
            <div className={classes.firstCatchUpContainer}>
                <div className={classes.body}>
                    <span className={classes.description}>
                        Nice work on your first Catch Up! Now let's show you the
                        rest of your Catch Up items
                    </span>
                </div>
                <div
                    className={classes.proceedMessage}
                    onClick={handleProceedClick}
                >
                    <i className="fal fa-chevron-left" />
                    Click to proceed
                    <i className="fal fa-chevron-right" />
                </div>
            </div>
        </Fade>
    );
};

export default CompleteFirstCatchUp;
