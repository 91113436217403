import { forwardRef, useEffect, useState } from 'react';

import CatchUpIllustration from 'assets/catchup-modal-illustration.png';
import { CTAButton3 } from 'components/_shared/buttons/index';

import { Dialog, LinearProgress, Slide } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    content: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        '& img': {
            height: '120px'
        }
    },
    dialog: {
        borderRadius: '16px'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        '& p': {
            color: '#1F2152'
        }
    },
    dialogTitle: {
        color: '#1F2152',
        padding: '0'
    },
    buttonContainer: {
        justifyContent: 'space-between',
        padding: '16px'
    }
}));

type CatchUpModalProps = {
    name: string;
    setShowCatchUp: (showCatchUp: boolean) => void;
    setShowCatchUpModal: (showCatchUpModal: boolean) => void;
};

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} timeout={1000} />;
});

const CatchUpModal: React.FC<CatchUpModalProps> = ({
    name,
    setShowCatchUp,
    setShowCatchUpModal
}) => {
    const classes = useStyles();

    const [progress, setProgress] = useState(100);

    const handleClose = () => {
        setShowCatchUpModal(false);
    };

    const handleGetCaughtUp = () => {
        setShowCatchUp(true);
        setShowCatchUpModal(false);
    };

    useEffect(() => {
        const duration = 10000;

        let start: null | number = null;

        const animate = (timestamp: number) => {
            if (!start) {
                start = timestamp;
            }
            const elapsed = timestamp - start;

            const newProgress = Math.max(100 - (elapsed / duration) * 100, 0);
            setProgress(newProgress);

            if (elapsed < duration) {
                requestAnimationFrame(animate);
            }
        };

        const frame = requestAnimationFrame(animate);

        return () => {
            cancelAnimationFrame(frame);
        };
    }, []);

    useEffect(() => {
        if (progress === 0) {
            setTimeout(() => {
                setShowCatchUpModal(false);
            }, 300);
        }
    }, [progress, setShowCatchUpModal]);

    return (
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="catch-up-modal-confirmation"
            PaperProps={{
                style: { borderRadius: '16px' }
            }}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: 'flex-start',
                    paddingTop: '5vh'
                },
                '@media (min-width: 769px)': {
                    '& .MuiDialog-container': {
                        paddingLeft: '300px'
                    }
                }
            }}
        >
            <DialogContent className={classes.content}>
                <img src={CatchUpIllustration} alt="Catch up illustration" />
                <div className={classes.dialogContent}>
                    <DialogTitle className={classes.dialogTitle}>
                        <b>Welcome Back!</b>
                    </DialogTitle>
                    <DialogContentText style={{ fontWeight: 300 }}>
                        To save you some time we gathered actions you might want
                        to take with {name} today.
                    </DialogContentText>
                    <DialogContentText>
                        <b>Do you want to get caught up?</b>
                    </DialogContentText>
                </div>
            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
                <CTAButton3 border invert onClick={handleClose}>
                    No
                </CTAButton3>
                <CTAButton3 onClick={handleGetCaughtUp}>
                    Let's get caught up!
                </CTAButton3>
            </DialogActions>
            <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                    backgroundColor: '#FFFFFF',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#999DFF'
                    }
                }}
            />
        </Dialog>
    );
};

export default CatchUpModal;
