import ReactGA from 'react-ga4';
import { useHistory } from 'react-router-dom';

import Background from 'assets/header-bg.png';

import Button from '@mui/material/Button';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background:
                'linear-gradient(180deg, rgba(31, 32, 81, 0.4) 0%, rgba(31, 32, 81, 0.6) 48.96%, #1F2051 100%)',
            height: 'auto',
            marginBottom: '20px',
            position: 'relative',
            width: '100%',
            '& > img': {
                height: '100%',
                objectFit: 'cover',
                width: '100%'
            }
        },
        subtitleContainer: {
            position: 'absolute',
            bottom: '16%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '16px',
            zIndex: 3,
            '@media (max-width: 768px)': {
                bottom: '-16%'
            }
        },
        subtitle: {
            color: '#FFFFFF',
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '29px'
        },
        signupBtn: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background:
                'linear-gradient(0deg, #9897E3, #9897E3), linear-gradient(270deg, rgba(184, 194, 255, 0.75) 0%, rgba(184, 194, 255, 0) 100%)',
            borderRadius: '50px',
            color: '#FFFFFF',
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '25px',
            padding: '16px 28px',
            textTransform: 'none',
            width: '100%',
            '& i': {
                fontSize: '18px'
            }
        }
    })
);

const ListHeader = ({
    eligibilityCheck = false,
    setEligibilityPopUp
}: {
    eligibilityCheck: boolean;
    setEligibilityPopUp: (open: boolean) => void;
}) => {
    const classes = useStyles();
    const history = useHistory();

    const handleIntakeClick = () => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Click',
            label: 'Sign Up New Member Button'
        });

        history.push('/intake');
    };

    const handleEligibilityCheckClick = () => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Click',
            label: 'Check Member Eligibility Button'
        });

        setEligibilityPopUp(true);
    };

    return (
        <header className={classes.header}>
            <img src={Background} alt="header" />
            <div className={classes.subtitleContainer}>
                <span className={classes.subtitle}>
                    Reward and support client progress
                </span>
                <Button
                    className={classes.signupBtn}
                    onClick={
                        !eligibilityCheck
                            ? handleIntakeClick
                            : handleEligibilityCheckClick
                    }
                >
                    Sign Up New Member
                    <i className="fas fa-arrow-right" />
                </Button>
            </div>
        </header>
    );
};

export default ListHeader;
