import classNames from 'classnames';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        height: '64px',
        width: '64px',
        '& i': {
            fontSize: '32px'
        }
    },
    noRecordsHeading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        '& h2': {
            color: '#7A4406',
            marginBottom: 0
        },
        '& span': {
            color: '#5A6A81',
            fontWeight: 300,
            maxWidth: '75%',
            textAlign: 'center'
        }
    },
    notFound: {
        backgroundColor: '#FEF3E7',
        border: '1px solid #7A4406',
        color: '#7A4406'
    }
}));

type NoRecordsFoundProps = {
    name: string;
    notEligibleMember: boolean;
};

const NoRecordsFound: React.FC<NoRecordsFoundProps> = ({
    name,
    notEligibleMember
}) => {
    const classes = useStyles();

    return (
        <div className={classes.noRecordsHeading}>
            <div
                className={classNames(classes.iconContainer, classes.notFound)}
            >
                <i className="far fa-exclamation-circle" />
            </div>
            {notEligibleMember ? (
                <h2>{name} is not eligible</h2>
            ) : (
                <h2>No matching records found</h2>
            )}
            <span>
                Please make sure all entered information is correct. If you
                believe this is a mistake, contact our support team.
            </span>
        </div>
    );
};

export default NoRecordsFound;
