import { useEffect, useRef, useState } from 'react';

import { EditButton } from '../buttons';
import FormInput from '../../../../_shared/FormInput';
import Underline from './Underline';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        editIcon: {
            height: 'inherit',
            width: 'inherit'
        },
        editIconContainer: {
            cursor: 'pointer',
            height: 'auto',
            margin: '0 0 0 15px',
            width: '1.5rem'
        },
        editUnderline: {
            backgroundColor: '#E3E4FA',
            border: '3px solid #E3E4FA',
            borderRadius: '10px',
            height: '0'
        },
        inputFields: {
            background: 'transparent',
            border: '0',
            borderRadius: '6px',
            color: '#1f1f51',
            fontFamily: 'Manrope',
            fontSize: '1.75rem',
            fontWeight: 500,
            letterSpacing: '-0.21px',
            padding: '10px 0px 0px 0px',
            resize: 'none',
            width: '100%',
            '&:focus': {
                outline: 'none !important'
            },
            '&:placeholder-shown': {
                fontStyle: 'italic'
            }
        },
        nickNameContainer: {
            marginBottom: '40px'
        },
        optionalLabel: {
            color: '#7A4406',
            fontSize: '0.8rem',
            fontWeight: 100,
            letterSpacing: '0.15em',
            marginLeft: '8px',
            opacity: 0.7
        },
        requiredLabel: {
            color: '#A80A2D',
            fontSize: '0.8rem',
            fontWeight: 100,
            letterSpacing: '0.15em',
            marginLeft: '8px',
            opacity: 0.7
        },
        underline: {
            backgroundColor: '#E3E4FA',
            border: '6px solid #E3E4FA',
            borderRadius: '10px',
            height: '0',
            marginBottom: '20px'
        }
    })
);

type NickNameInputProps = {
    inputTitleClass: (name: string) => string;
    intakeProfileName: string;
    nickname: string;
    remoteCopy: {
        label: string;
        placeholder: string;
        required: boolean;
    };
    setIntakeProfileName: (name: string) => void;
    setNickname: (name: string) => void;
};

const NickNameInput: React.FC<NickNameInputProps> = ({
    inputTitleClass,
    intakeProfileName,
    nickname,
    remoteCopy,
    setIntakeProfileName,
    setNickname
}) => {
    const classes = useStyles();
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { label, placeholder, required } = remoteCopy;

    const [isActive, setIsActive] = useState(false);
    const [savedName, setSavedName] = useState(intakeProfileName.length > 0);

    const handleBlurClick = () => {
        if (nickname.length > 0) {
            handleSaveClick();
        }
    };

    const handleEditClick = () => {
        setIsActive(true);
        setSavedName(false);
    };

    const handleInputClick = () => {
        setIsActive(true);
        setSavedName(false);
    };

    const handleSaveClick = () => {
        if (nickname.length) {
            setSavedName(true);
            setIntakeProfileName(nickname);
        }
    };

    useEffect(() => {
        if (inputRef.current && isActive && !savedName) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(
                nickname.length,
                nickname.length
            );
        }
    });

    useEffect(() => {
        if (intakeProfileName) {
            setSavedName(true);
        }
    }, [intakeProfileName]);

    useEffect(() => {
        if (intakeProfileName.length && !nickname.length) {
            setIntakeProfileName('');
            setIsActive(false);
        }
    }, [intakeProfileName, nickname, setIntakeProfileName]);

    return (
        <div className={classes.nickNameContainer}>
            <span className={inputTitleClass(intakeProfileName)}>
                {label}
                <span
                    className={
                        classes[required ? 'requiredLabel' : 'optionalLabel']
                    }
                >
                    {`(${required ? 'REQUIRED' : 'OPTIONAL'})`}
                </span>
            </span>
            {!savedName ? (
                <FormInput
                    autoComplete="on"
                    className={classes.inputFields}
                    id="nickname-input"
                    name="name"
                    onBlur={handleBlurClick}
                    onClick={() => handleInputClick()}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setNickname(e.target.value)
                    }
                    placeholder={placeholder}
                    ref={inputRef}
                    saveInput={handleSaveClick}
                    value={nickname}
                />
            ) : (
                <EditButton
                    handleEditClick={handleEditClick}
                    inputValState={intakeProfileName}
                    saveInputState={savedName}
                />
            )}
            {!savedName && <Underline />}
        </div>
    );
};

export default NickNameInput;
