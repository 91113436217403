export const renderCardType = (
    debitType: 'givecard' | 'pex' | 'virtual-givecard' | null
) => {
    switch (debitType) {
        case 'givecard':
            return 'GiveCard';
        case 'pex':
            return 'PEX Card';
        case 'virtual-givecard':
            return 'GiveCard';
        default:
            return 'Debit Card';
    }
};
