import SamaritanFetch from './httpClient';

import AuthManager from '../auth';

export const createMemberCaseload = (memberID: number) => {
    const memberCaseload = {
        ctp_caseload: {
            homeless_id: memberID,
            status: 'active'
        }
    };

    return SamaritanFetch.v3.post('ctp_caseloads', {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(memberCaseload)
    });
};

export const fetchHomelesses = () => {
    const auth = AuthManager.getInstance();
    const deploymentOrgIds = auth.getUserDeploymentIds();

    interface Query {
        samaritan_members: {
            profile_stage: string[];
            deployment_id?: string[];
        };
        template: string;
    }

    const query: Query = {
        samaritan_members: {
            profile_stage: [
                'EXITED',
                'LIVE',
                'NEEDS_REVIEW',
                'PENDING',
                'UNSUCCESSFUL_PENDING'
            ]
        },
        template: 'small'
    };

    if (deploymentOrgIds?.length) {
        query.samaritan_members.deployment_id = deploymentOrgIds;
    }

    return SamaritanFetch.v3('/samaritan_members', { query });
};

export const fetchHomelessLanguagePreferences = () => {
    return SamaritanFetch.v3('/samaritan_members/language_preferences');
};

export const fetchUserCaseloads = () => {
    return SamaritanFetch.v3('ctp_caseloads', {
        method: 'GET'
    });
};

export const removeMemberFromMyMembers = (caseloadID: number) => {
    const cancelledStatus = {
        ctp_caseload: {
            status: 'cancelled'
        }
    };

    return SamaritanFetch.v3.put(`ctp_caseloads/${caseloadID}`, {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(cancelledStatus)
    });
};

export const saveMemberToMyMembers = (caseloadID: number) => {
    const activeStatus = {
        ctp_caseload: {
            status: 'active'
        }
    };

    return SamaritanFetch.v3.put(`ctp_caseloads/${caseloadID}`, {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(activeStatus)
    });
};

export const searchForMember = async (
    searchTerms: string[],
    searchValues: string[]
) => {
    const searchTermValues = {
        birthday: 'date_of_birth',
        firstName: 'pii_first_name',
        lastName: 'pii_last_name',
        insuranceID: 'pii_member_id'
    } as { [key: string]: string };

    if (searchTerms.length !== searchValues.length) {
        throw new Error(
            'searchTerms and searchValues must have the same length'
        );
    }

    const queryObject: { [key: string]: string } = {};

    searchTerms.forEach((term, index) => {
        const mappedTerm = searchTermValues[term];
        if (mappedTerm) {
            queryObject[mappedTerm] = searchValues[index];
        }
    });

    const fetchMembers = async (query: object) => {
        const response = await SamaritanFetch.v3('samaritan_members/search', {
            query: { samaritan_member: query }
        });
        if (!response.ok) throw new Error('Error searching for member');
        return response.json();
    };

    if (Object.keys(queryObject).length === 4) {
        try {
            const [firstResult, secondResult] = await Promise.all([
                fetchMembers({
                    date_of_birth: queryObject.date_of_birth,
                    pii_first_name: queryObject.pii_first_name,
                    pii_last_name: queryObject.pii_last_name
                }),
                fetchMembers({ pii_member_id: queryObject.pii_member_id })
            ]);

            return [
                ...firstResult.samaritan_members,
                ...secondResult.samaritan_members
            ];
        } catch (error) {
            console.error('Failed to fetch members:', error);
            throw error;
        }
    }

    return fetchMembers(queryObject);
};

export const updateUserLanguagePreference = (
    memberID: number,
    language: string
) => {
    return SamaritanFetch.v3.put(`/samaritan_members/${memberID}`, {
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            samaritan_member: {
                language_preference: language
            }
        })
    });
};