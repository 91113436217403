import { useEffect, useState } from 'react';

import EditButton from 'components/intake/Account/components/buttons/EditButton';
import Input from 'components/holder_profile/profile_2.0/ProfileCompletion/components/Input';
import Modal2 from 'components/_shared/Modal2';
import Overlay from 'components/_shared/Overlay';
import { CTAButton3 } from 'components/_shared/buttons';

import { useDebitCardActions } from 'hooks/useDebitCardActions';
import useUpdatePrivateProfile from 'hooks/useUpdatePrivateProfile';

import { emailValid, possessiveSuffix } from 'util/index';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '24px',
        color: '#1F2152',
        padding: '40px 0'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        '& button': {
            width: '60%'
        }
    },
    errorMessage: {
        color: 'red',
        fontSize: '14px',
        padding: '0 56px',
        textAlign: 'center'
    },
    inputContainer: {
        width: '70%'
    },
    inputTitle: {
        fontSize: '16px',
        lineHeight: '100%',
        marginBottom: '0.25rem',
        width: 'fit-content'
    },
    subtitle: {
        margin: '16px',
        padding: '0 56px',
        maxWidth: '460px'
    }
}));

type VirtualCardProps = {
    email: null | string;
    handleClose: () => void;
    id: number;
    name: string;
};

const VirtualCard: React.FC<VirtualCardProps> = ({
    email,
    handleClose,
    id,
    name
}) => {
    const classes = useStyles();

    const {
        assignDebitCard,
        error: debitCardError,
        errorMessage: debitCardErrorMessage,
        loading,
        resetDebitState: reset,
        success
    } = useDebitCardActions();
    const {
        error: profileUpdateError,
        isLoading: updatePrivateProfileLoading,
        updatePrivateProfile
    } = useUpdatePrivateProfile();

    const showIssuingCard =
        loading || (updatePrivateProfileLoading && !loading);

    const [isSaved, setIsSaved] = useState(email ? true : false);
    const [memberEmail, setMemberEmail] = useState(email ? email : '');

    const disabled =
        !emailValid(memberEmail) ||
        debitCardError ||
        loading ||
        !memberEmail ||
        updatePrivateProfileLoading;

    const handleAssignCard = async () => {
        try {
            if (!emailValid(memberEmail)) {
                return;
            }

            if ((!email || !isSaved) && memberEmail) {
                const formData = new FormData();
                formData.append('samaritan_member[email]', memberEmail);

                setIsSaved(true);

                const response = await updatePrivateProfile(id, formData);

                if (!response || response.errors) {
                    console.error('Profile update failed:', response);
                    return;
                }
            }

            await assignDebitCard(id, 'virtual-givecard', '');
        } catch (error) {
            console.error('Failed to assign debit card:', error);
        }
    };

    const handleEditClick = () => {
        if (updatePrivateProfileLoading) {
            return;
        }

        if (debitCardError) {
            reset();
        }

        setIsSaved(false);
    };

    const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (!emailValid(memberEmail)) {
                return;
            }

            setIsSaved(true);
            handleAssignCard();
        }
    };

    useEffect(() => {
        reset();
    }, [reset]);

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                handleClose();
            }, 1500);
        }
    }, [handleClose, success]);

    return (
        <Modal2 onClose={() => handleClose()} title="Issue Virtual Card">
            {(loading || updatePrivateProfileLoading || success) && (
                <Overlay
                    text={showIssuingCard ? 'Issuing card..' : 'Card issued!'}
                    loading={showIssuingCard}
                />
            )}
            <div className={classes.body}>
                <h3 style={{ margin: 0 }}>Virtual Debit Card</h3>
                <span className={classes.subtitle}>
                    The virtual card assigned to {name} will be sent to them via
                    email.
                    {!email ? (
                        ` Please provide their most current email address
                    below.`
                    ) : (
                        <>
                            <br />
                            <br />
                            Please confirm that the email address below is
                            accurate or update it before proceeding.
                        </>
                    )}
                </span>
                <div className={classes.inputContainer}>
                    {!memberEmail.length || !isSaved ? (
                        <>
                            <h3 className={classes.inputTitle}>
                                {possessiveSuffix(name)} Email Address:
                            </h3>
                            <Input
                                inputType="email"
                                onChange={(e) => setMemberEmail(e.target.value)}
                                onKeyDown={(e) => handleEnterKey(e)}
                                placeholder="example@mail.com"
                                validator={emailValid}
                                value={memberEmail}
                            />
                        </>
                    ) : (
                        <>
                            <h3 className={classes.inputTitle}>
                                Email Address:
                            </h3>
                            <EditButton
                                handleEditClick={handleEditClick}
                                inputValState={memberEmail || ''}
                                saveInputState={isSaved}
                            />
                        </>
                    )}
                </div>
                {debitCardError || profileUpdateError ? (
                    <div className={classes.errorMessage}>
                        {(debitCardError && debitCardErrorMessage) ||
                            profileUpdateError}
                    </div>
                ) : null}
                <div className={classes.buttonContainer}>
                    {email ? (
                        <CTAButton3
                            disabled={disabled}
                            onClick={handleAssignCard}
                        >
                            {!isSaved
                                ? `Update Email & Issue Virtual Card`
                                : `Issue Virtual Card`}
                        </CTAButton3>
                    ) : (
                        <CTAButton3
                            disabled={disabled}
                            onClick={handleAssignCard}
                        >
                            Update Email & Issue Virtual Card
                        </CTAButton3>
                    )}
                </div>
            </div>
        </Modal2>
    );
};

export default VirtualCard;
