// TODO: FIX ts-nocheck
// @ts-nocheck
import { useDispatch } from 'react-redux';

import {
    DebitCard as DebitCardType,
    DebitCardOptions,
    Deployments,
    Homeless
} from 'types';

import {
    Complete,
    Language,
    Need,
    Prompt,
    Reenroll,
    Reminder,
    Update
} from './components';
import DebitCard from '../Funds/DebitCard';
import Edit from '../CaseManagement/components/Edit';
import Filter from '../MemberHistory/components/Filter';
import EditContactInfo from '../PrivateInfo/components/EditContactInfo';
import EditMemberInfo from '../PrivateInfo/components/EditMemberInfo';
import LifeStoryUpdate from '../PublicInfo/LifeStory/components/LifeStoryUpdate';
import LoadingCircle from 'components/_shared/LoadingCircle';
import Modal2 from 'components/_shared/Modal2';
import UnenrollMember from '../PrivateInfo/UnenrollMember';

import { checkCurrentMonth } from '../CaseManagement/utils';

import {
    editGoalActionThunk,
    updateGoalActionStatus
} from 'redux/actions/goal_actions';
import { updateBonusAmount } from 'redux/actions/homeless';
import { cancelNeed, completeNeed, newNeedThunk } from 'redux/actions/needs';

type PopUpProps = {
    actionStepID?: null | number;
    amount?: string;
    answer?: string;
    assignDebitCard?: (
        memberID: number,
        type: 'givecard' | 'pex' | 'virtual-givecard',
        last4: number,
        token: string
    ) => void;
    balance?: number;
    catchUpID?: number;
    classes?: object;
    customGoal?: string;
    content: string;
    dateISO?: string;
    debitCardOptions?: DebitCardOptions[];
    debitInfo?: DebitCardType;
    debitType?: 'givecard' | 'pex' | 'virtual-givecard' | null;
    deployments?: Deployments[];
    editAction?: null | { created_at: string; match_value: number };
    editNeed?: object;
    filterDate?: Date | null;
    filterType?: string;
    fromCatchUp?: boolean;
    fullCardHistory?: object;
    handleModalClick: (value: boolean) => void;
    handleSave?: () => void;
    hasQOLMeasures?: boolean;
    historyFilters?: object;
    holder?: Homeless;
    homelessNeeds?: object;
    id?: number;
    isEditingGoalAction?: boolean;
    isEditingNeed?: boolean;
    isFetchingDebitHistory?: boolean;
    isSubmitting?: boolean;
    maxBonusAmount?: number;
    name?: string;
    needID?: number;
    needs?: object;
    newBirthday?: null | string;
    newDeployment?: null | number;
    newEmail?: null | string;
    newFirstName?: null | string;
    newHolderOrg?: null | string;
    newIdentificationNumber?: null | string;
    newMiddleName?: null | string;
    newPhoneNumber?: null | string;
    newLastName?: null | string;
    newQR?: null | string;
    orgs?: {
        id: number;
        name: string;
    }[];
    privateInfo?: boolean;
    profileItemType?: null | string;
    prompt?: string;
    qolIDs?: number[];
    qolMeasures?: object;
    questionID?: number;
    questionIndex?: number;
    questionsLength?: number;
    recentCardHistory?: object;
    remainingBonusAmount?: number;
    reminderAction?: null | object | string;
    replaceCardView?: boolean;
    savedQOLs?: object;
    setActionStepID?: (value: number | null) => void;
    setAssignDebit?: (value: boolean) => void;
    setBirthday?: (value: string) => void;
    setCustomGoal?: (value: string) => void;
    setDebitType?: (
        value: 'givecard' | 'pex' | 'virtual-givecard' | null
    ) => void;
    setDeployment?: (value: string) => void;
    setEditAction?: (value: object | null) => void;
    setEditNeed?: (value: object | null) => void;
    setEmail?: (value: string) => void;
    setFilterDate?: (value: Date | null) => void;
    setFilterEndDate?: (value: Date | null) => void;
    setFilterType?: (value: string) => void;
    setFirstName?: (value: string) => void;
    setFullCardHistory?: (value: any) => void;
    setHolderOrg?: (value: string) => void;
    setIdentificationNumber?: (value: string) => void;
    setLastName?: (value: string) => void;
    setMiddleName?: (value: string) => void;
    setName?: (value: string) => void;
    setNewLanguage?: (value: string) => void;
    setNeedID?: (value: number | null) => void;
    setPhoneNumber?: (value: string) => void;
    setPopUp?: (value: boolean) => void;
    setPopUpOpen?: (value: boolean) => void;
    setQolIDs?: (value: number[]) => void;
    setQR?: (value: string) => void;
    setQuestionIndex?: (value: number) => void;
    setRecentCardHistory?: (value: any) => void;
    setReminder?: (value: boolean) => void;
    setSavedQOLs?: (value: object) => void;
    setUnenroll?: (value: boolean) => void;
    setUpdatedActionIDs?: (value: number[]) => void;
    setUpdatedNeedIDs?: (value: number[]) => void;
    setUpdateSDOHStatus?: (value: boolean) => void;
    setDateButtonActive?: (value: boolean) => void;
    setTypeButtonActive?: (value: boolean) => void;
    setDateISO?: (value: string) => void;
    total?: number;
    totalBonusAmount?: number;
    touchpointAnswers?: object[];
    touchpointQuestions?: object[];
    updateAction?: object;
    updatePinView?: boolean;
    updateNeed?: object;
    updatedActionIDs?: number[] | [];
    updatedNeedIDs?: number[] | [];
    updateHomelessLifeStory?: (id: number, data: any) => void;
    usingVirtualCards?: boolean;
    validateEmail?: (value: string) => boolean;
    validatePhone?: (value: string) => boolean;
};

const PopUp: React.FC<PopUpProps> = ({
    content,
    handleModalClick,
    needs,
    ...props
}) => {
    const dispatch = useDispatch();

    const {
        actionStepID,
        setActionStepID,
        dateISO,
        setDateISO,
        editAction,
        setEditAction,
        editNeed,
        setEditNeed,
        fullCardHistory,
        setFullCardHistory,
        hasQOLMeasures,
        holder,
        id,
        isEditingGoalAction,
        isEditingNeed,
        isFetchingDebitHistory,
        maxBonusAmount,
        name,
        needID,
        setNeedID,
        recentCardHistory,
        replaceCardView,
        setRecentCardHistory,
        remainingBonusAmount,
        reminderAction,
        setFilterEndDate,
        filterDate,
        setFilterDate,
        filterType,
        setFilterType,
        setReminder,
        setUnenroll,
        updatedActionIDs,
        setUpdatedActionIDs,
        updatedNeedIDs,
        updatePinView,
        setUpdatedNeedIDs,
        setUpdateSDOHStatus,
        updateHomelessLifeStory,
        historyFilters,
        setDateButtonActive,
        setTypeButtonActive,
        totalBonusAmount,
        updateAction,
        updateNeed,
        touchpointAnswers,
        touchpointQuestions,
        homelessNeeds,
        amount,
        prompt,
        setAssignDebit,
        profileItemType,
        debitCardOptions,
        debitType,
        setDebitType,
        fromCatchUp,
        assignDebitCard,
        debitInfo,
        balance,
        setPopUp,
        catchUpID,
        isSubmitting,
        usingVirtualCards
    } = props;

    const {
        classes: editClasses,
        newFirstName,
        setFirstName,
        newMiddleName,
        setMiddleName,
        newLastName,
        setLastName,
        newBirthday,
        setBirthday,
        deployments,
        newDeployment,
        setNewDeployment,
        newIdentificationNumber,
        setIdentificationNumber,
        newQR,
        setQR,
        newPhoneNumber,
        setPhoneNumber,
        newEmail,
        setEmail,
        newLanguage,
        setNewLanguage,
        updateLanguage,
        setUpdateLanguage,
        privateInfo,
        handleSave,
        validateEmail,
        validatePhone
    } = props;

    const handleCloseClick = () => {
        if (content === 'debit card' && setDebitType) {
            setDebitType(null);
        }

        if (
            content === 'edit action step' &&
            setActionStepID &&
            setEditAction
        ) {
            setActionStepID(null);
            setEditAction(null);
        }

        if (content === 'edit need' && setEditNeed && setNeedID) {
            setEditNeed(null);
            setNeedID(null);
        }

        if (content === 'filter date' && setDateButtonActive && setFilterDate) {
            setDateButtonActive(false);
            setFilterDate(null);
        }

        if (content === 'filter type' && setFilterType && setTypeButtonActive) {
            setFilterType('');
            setTypeButtonActive(false);
        }

        if (content === 'unenroll') {
            window.history.replaceState({}, document.title);
        }

        handleModalClick(false);
    };

    const handleCompleteClick = () => {
        if (hasQOLMeasures && setUpdateSDOHStatus) {
            setUpdateSDOHStatus(true);
        }

        if (catchUpID) {
            dispatch(
                updateGoalActionStatus(actionStepID, 'completed', catchUpID)
            );
        }

        if (setUpdatedActionIDs && content === 'complete action step') {
            dispatch(updateGoalActionStatus(actionStepID, 'completed'));
            setUpdatedActionIDs([...updatedActionIDs, actionStepID]);
        }

        if (setUpdatedNeedIDs && content === 'complete need') {
            dispatch(completeNeed(needID));
            setUpdatedNeedIDs([...updatedNeedIDs, needID]);
        }

        handleModalClick(false);
    };

    const handleDeleteClick = () => {
        if (
            setUpdatedActionIDs &&
            content === 'edit action step' &&
            editAction
        ) {
            dispatch(updateGoalActionStatus(actionStepID, 'cancelled'));
            setUpdatedActionIDs([...updatedActionIDs, actionStepID]);

            if (checkCurrentMonth(editAction.created_at)) {
                dispatch(updateBonusAmount(-editAction.match_value));
            }
        }

        if (setUpdatedNeedIDs && content === 'edit need') {
            dispatch(cancelNeed(needID));
            setUpdatedNeedIDs([...updatedNeedIDs, needID]);
        }

        handleModalClick(false);
    };

    const handleSaveEdit = (
        data: { amount: string; description: string },
        catchUpID?: number
    ) => {
        const { amount, description, goal, quality_of_life_measures } = data;
        const moneyValue =
            amount === 'No Amount' || amount === 'No Bonus'
                ? 0
                : amount.replace(/\$/g, '');

        if (content === 'edit action step' && editAction) {
            const oldAmount = editAction.match_value;
            const createdAt = editAction.created_at;

            dispatch(
                editGoalActionThunk(
                    description,
                    moneyValue,
                    dateISO,
                    actionStepID,
                    goal.id,
                    quality_of_life_measures,
                    catchUpID
                )
            );

            if (
                checkCurrentMonth(createdAt) &&
                Number(moneyValue) > Number(oldAmount)
            ) {
                dispatch(
                    updateBonusAmount(Number(moneyValue) - Number(oldAmount))
                );
            }

            if (
                checkCurrentMonth(createdAt) &&
                Number(moneyValue) < Number(oldAmount)
            ) {
                dispatch(updateBonusAmount(-(oldAmount - Number(moneyValue))));
            }

            if (!isEditingGoalAction) {
                handleModalClick(false);
            }
        } else {
            dispatch(newNeedThunk(description, moneyValue, dateISO, needID));

            if (!isEditingNeed) {
                handleModalClick(false);
            }
        }
    };

    const renderComponent = () => {
        switch (content) {
            case 'complete action step':
                return (
                    <Complete
                        handleCompleteClick={handleCompleteClick}
                        type="action step"
                    />
                );
            case 'complete need':
                return (
                    <Complete
                        handleCompleteClick={handleCompleteClick}
                        type="need"
                    />
                );
            case 'debit card':
                return <DebitCard {...debitCardProps} />;
            case 'edit action step':
                return (
                    <Edit
                        actionStep={editAction}
                        catchUpID={props.catchUpID}
                        dateISO={dateISO}
                        setDateISO={setDateISO}
                        deleteAction={handleDeleteClick}
                        isEditing={isEditingGoalAction}
                        maxBonusAmount={maxBonusAmount}
                        remainingBonusAmount={remainingBonusAmount}
                        saveAction={handleSaveEdit}
                        setUpdatedActionIDs={setUpdatedActionIDs}
                        totalBonusAmount={totalBonusAmount}
                        type="action step"
                    />
                );
            case 'edit contact info':
                return (
                    <EditContactInfo
                        classes={editClasses}
                        newPhoneNumber={newPhoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        newEmail={newEmail}
                        setEmail={setEmail}
                        newLanguage={newLanguage}
                        setNewLanguage={setNewLanguage}
                        updateLanguage={updateLanguage}
                        setUpdateLanguage={setUpdateLanguage}
                        handleSave={handleSave}
                        holder={holder}
                        validateEmail={validateEmail}
                        validatePhone={validatePhone}
                    />
                );
            case 'edit member info':
                return (
                    <EditMemberInfo
                        classes={editClasses}
                        holder={holder}
                        setUnenroll={setUnenroll}
                        newFirstName={newFirstName}
                        setFirstName={setFirstName}
                        newMiddleName={newMiddleName}
                        setMiddleName={setMiddleName}
                        newLastName={newLastName}
                        setLastName={setLastName}
                        newBirthday={newBirthday}
                        setBirthday={setBirthday}
                        deployments={deployments}
                        newDeployment={newDeployment}
                        setNewDeployment={setNewDeployment}
                        newIdentificationNumber={newIdentificationNumber}
                        setIdentificationNumber={setIdentificationNumber}
                        newQR={newQR}
                        setQR={setQR}
                        handleSave={handleSave}
                    />
                );
            case 'edit need':
                return (
                    <Edit
                        need={editNeed}
                        dateISO={dateISO}
                        setDateISO={setDateISO}
                        deleteAction={handleDeleteClick}
                        isEditing={isEditingNeed}
                        saveAction={handleSaveEdit}
                        setUpdatedNeedIDs={setUpdatedNeedIDs}
                        type="need"
                    />
                );
            case 'exited':
                return <Reenroll holder={holder} />;
            case 'filter date':
                return (
                    <Filter
                        filterByType={'date'}
                        filterDate={filterDate}
                        handleModalClick={handleModalClick}
                        setDateButtonActive={setDateButtonActive}
                        setFilterEndDate={setFilterEndDate}
                        setFilterDate={setFilterDate}
                    />
                );
            case 'filter type':
                return (
                    <Filter
                        filterByType={'type'}
                        filterType={filterType}
                        handleModalClick={handleModalClick}
                        historyFilters={historyFilters}
                        setFilterType={setFilterType}
                        setTypeButtonActive={setTypeButtonActive}
                    />
                );
            case 'life story':
                return (
                    <LifeStoryUpdate
                        id={id}
                        isSubmitting={isSubmitting}
                        name={name}
                        updateHomelessLifeStory={updateHomelessLifeStory}
                    />
                );
            case 'reminder':
                return (
                    <Reminder
                        action={reminderAction}
                        catchUpItemID={props.catchUpID}
                        id={actionStepID}
                        name={holder && holder.name}
                        setReminder={setReminder}
                    />
                );
            case 'supplemental question':
                return (
                    <Prompt
                        answer={props.answer}
                        catchUpID={props.catchUpID}
                        homelessID={id}
                        prompt={prompt ?? ''}
                        profileItemType={profileItemType}
                        questionID={props.questionID ?? 0}
                        questionIndex={props.questionIndex ?? 0}
                        questionsLength={props.questionsLength ?? 0}
                        setAddAnswer={handleCloseClick}
                        setQuestionIndex={props.setQuestionIndex}
                    />
                );
            case 'unenroll':
                return (
                    <UnenrollMember
                        holder={holder}
                        setShowUnenrollMember={setUnenroll}
                    />
                );
            case 'update language':
                return (
                    <Language
                        homelessInfo={holder}
                        handleCloseClick={handleCloseClick}
                        privateInfo={privateInfo}
                        setNewLanguage={setNewLanguage}
                    />
                );
            case 'update need':
                return (
                    <Need
                        amount={amount}
                        handleCloseClick={handleCloseClick}
                        name={name}
                        needs={homelessNeeds}
                    />
                );
            case 'update SDOH status':
                return (
                    <Update
                        action={updateAction}
                        catchUpItemID={catchUpID}
                        handleCloseClick={handleCloseClick}
                        homelessID={holder?.id ?? 0}
                        name={holder?.name ?? ''}
                        need={updateNeed ?? {}}
                        touchpointAnswers={touchpointAnswers ?? []}
                        touchpointQuestions={touchpointQuestions ?? []}
                    />
                );
            default:
                return '';
        }
    };

    // const renderTitle = () => {
    //     switch (content) {
    //         case 'complete action step':
    //             return 'Complete Action Step';
    //         case 'complete need':
    //             return 'Complete Need';
    //         case 'debit card':
    //             return 'Managing Debit Card';
    //         case 'edit action step':
    //             return 'Editing Action Step';
    //         case 'edit contact info':
    //             return 'Updating Contact Information';
    //         case 'edit member info':
    //             return 'Updating Member Information';
    //         case 'edit need':
    //             return 'Editing Need';
    //         case 'exited':
    //             return 'Member Unenrolled';
    //         case 'filter date':
    //             return 'Filter by date';
    //         case 'filter type':
    //             return 'Filter by type';
    //         case 'life story':
    //             return 'Add New Update';
    //         case 'public profile':
    //             return 'Editing Public Profile';
    //         case 'reminder':
    //             return 'Send a Reminder';
    //         case 'supplemental question':
    //             return 'Answering a Prompt';
    //         case 'unenroll':
    //             return 'Unenroll Member';
    //         case 'update language':
    //             return 'Update Communication Language';
    //         case 'update need':
    //             return 'Complete Need';
    //         case 'update SDOH status':
    //             return 'Status Capture';
    //         default:
    //             return '';
    //     }
    // };

    const debitCardProps = {
        assignDebitCard,
        balance,
        catchUpID,
        debitCardOptions,
        debitInfo,
        debitType,
        fromCatchUp,
        fullCardHistory,
        handleModalClick,
        holder,
        isFetchingDebitHistory,
        needs,
        recentCardHistory,
        replaceCardView,
        updatePinView,
        setAssignDebit,
        setDebitType,
        setFullCardHistory,
        setPopUp,
        setRecentCardHistory,
        usingVirtualCards
    };

    return (
        <Modal2
            onClose={handleCloseClick}
            title={`${content} Pop Up`}
            width={content === 'edit action step' ? '600px' : '660px'}
        >
            {(content === 'edit action step' && !editAction) ||
            (content === 'edit need' && !editNeed) ? (
                <div style={{ padding: '20px' }}>
                    <LoadingCircle />
                </div>
            ) : (
                renderComponent()
            )}
        </Modal2>
    );
};

export default PopUp;
