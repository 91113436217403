import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateHomelessInfoField } from 'redux/actions/homeless';

import { updateHomelessPrivateInfo } from 'api/homeless';

const useUpdatePrivateProfile = () => {
    const dispatch = useDispatch();

    const [error, setError] = useState<null | string>(null);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const updatePrivateProfile = useCallback(
        async (memberId: number, data: any) => {
            try {
                setError(null);
                setIsError(false);
                setIsLoading(true);

                const res = await updateHomelessPrivateInfo(memberId, data);

                if (res?.errors?.length) {
                    const { attribute, description } = res.errors[0];

                    setError(
                        `${attribute ? attribute + ' ' : ''}${description}`
                    );
                    setIsError(true);
                    setIsSuccess(false);
                } else {
                    data.forEach((value: string, key: string) => {
                        const matchedKey = key.match(/\[(.*?)\]/);
                        let formattedKey = matchedKey ? matchedKey[1] : key;

                        formattedKey = formattedKey.replace(/^pii_/, '');

                        if (formattedKey === 'date_of_birth') {
                            formattedKey = 'birthday';
                        }

                        if (formattedKey === 'phone') {
                            formattedKey = 'phone_number';
                        }

                        dispatch(updateHomelessInfoField(formattedKey, value));
                    });

                    setIsError(false);
                    setIsSuccess(true);

                    return res;
                }
            } catch (err) {
                console.error('Update private profile failed:', err);
                setError(err instanceof Error ? err.message : 'Unknown error');
                setIsError(true);
                setIsSuccess(false);
            } finally {
                setIsLoading(false);
            }
        },
        [dispatch]
    );

    const reset = useCallback(() => {
        setIsSuccess(false);
        setIsError(false);
        setIsLoading(false);
        setError(null);
    }, []);

    return {
        error,
        isError,
        isLoading,
        isSuccess,
        reset,
        updatePrivateProfile
    };
};

export default useUpdatePrivateProfile;
