import classNames from 'classnames';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    eligible: {
        backgroundColor: '#E9FBF2',
        border: '1px solid #1B9757',
        color: '#1B9757'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        height: '64px',
        width: '64px',
        '& i': {
            fontSize: '32px'
        }
    },
    memberHeading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        '& img': {
            border: '2px solid #1B9757',
            borderRadius: '50%',
            height: '160px',
            width: '160px'
        },
        '& span': {
            color: '#1F2152',
            fontSize: '24px',
            fontWeight: 700
        }
    },
    photoContainer: {
        position: 'relative',
        '& img': {
            objectFit: 'cover'
        }
    },
    photoIconContainer: {
        position: 'absolute',
        bottom: '8px',
        right: '8px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '32px',
        width: '32px'
    }
}));

const MemberStatus: React.FC<{
    type: 'eligible' | 'found';
    name: string;
    photo?: string;
}> = ({ type, name, photo }) => {
    const classes = useStyles();

    return (
        <div className={classes.memberHeading}>
            {type === 'eligible' ? (
                <>
                    <div
                        className={classNames(
                            classes.iconContainer,
                            classes.eligible
                        )}
                    >
                        <i className="far fa-check" />
                    </div>
                    <span style={{ color: '#1B9757' }}>
                        {name} is eligible!
                    </span>
                </>
            ) : (
                <>
                    <div className={classes.photoContainer}>
                        <img src={photo} alt="member" />
                        <div
                            className={classNames(
                                classes.photoIconContainer,
                                classes.eligible
                            )}
                        >
                            <i className="far fa-check" />
                        </div>
                    </div>
                    <span>{name} is already a Member!</span>
                </>
            )}
        </div>
    );
};

export default MemberStatus;
