import SamaritanFetch from './httpClient';
    
export const assignGiveCard = (
    memberID: number,
    last4: string,
    token: string,
    externalID?: string
) => {
    const body = {
        last4
    } as {
        last4: string;
        external_id?: string;
        virtual_assign?: boolean;
    };

    if (externalID) {
        body.external_id = externalID;
    }

    if (last4.length === 0) {
        body.virtual_assign = true;
    }

    return SamaritanFetch.v3.post(
        `/samaritan_members/${memberID}/givecard/card/assign`,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token token=${token}`
            },
            body: JSON.stringify(body)
        }
    );
};

export const closeGiveCard = (memberID: number) =>
    SamaritanFetch.v3.post(
        `/samaritan_members/${memberID}/givecard/card/close`
    );

export const fetchDebitTypes = () =>
    SamaritanFetch.v3('/remote_copies/debit_card_options', {
        headers: {
            Accept: 'application/json'
        }
    });

export const fetchGiveCardHistory = (memberID: number, full: boolean) => {
    const query = {} as {
        page?: number;
        page_size?: number;
    };

    if (!full) {
        query.page = 1;
        query.page_size = 3;
    }

    return SamaritanFetch.v3(
        `/samaritan_members/${memberID}/givecard/transaction_history`,
        {
            query
        }
    );
};

export const transferGiveCardFunds = (memberID: number, amount: number) => {
    const body = {
        amount
    };

    return SamaritanFetch.v3.post(
        `/samaritan_members/${memberID}/givecard/fund`,
        {
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }
    );
};

export const updateGiveCardPin = (memberID: number, pin: number) => {
    const body = {
        new_pin: pin
    };

    return SamaritanFetch.v3.post(
        `/samaritan_members/${memberID}/givecard/update_pin`,
        {
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }
    );
};
