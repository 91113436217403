import { useDispatch, useSelector } from 'react-redux';

import { setIntakeAutofill } from 'redux/actions/intake';

import { AppState } from 'types';

const useIntakeAutofill = () => {
    const dispatch = useDispatch();
    const intakeState = useSelector((state: AppState) => state.intake);

    const formatPhone = (phone: string) => {
        if (!phone) {
            return '';
        }

        const removeRegionCode = phone.replace('+1', '');

        if (removeRegionCode.length === 10) {
            return `(${removeRegionCode.slice(
                0,
                3
            )}) ${removeRegionCode.slice(3, 6)}-${removeRegionCode.slice(
                6,
                removeRegionCode.length
            )}`;
        }

        return removeRegionCode;
    };

    const autofillIntake = (intakeData: any) => {
        const {
            beacon_id,
            date_of_birth,
            email,
            name,
            phone,
            photo,
            pii_first_name,
            pii_last_name,
            pii_member_id
        } = intakeData;

        const formattedPhone = formatPhone(phone);

        const intakeFormData = {
            beacon_id: beacon_id,
            birthday: date_of_birth,
            email: email,
            fromEligibilityChecker: true,
            member_id: pii_member_id,
            phone: formattedPhone,
            privateFirstName: pii_first_name,
            privateFullName: `${pii_first_name} ${pii_last_name}`,
            privateLastName: pii_last_name,
            profileName: name,
            profilePic: photo
        };

        dispatch(setIntakeAutofill(intakeFormData));
    };

    return { intakeState, setIntakeAutofill, autofillIntake };
};

export default useIntakeAutofill;
