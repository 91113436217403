import { CatchUpItems } from 'types';

import { possessiveSuffix } from 'util/index';

import Fade from '@mui/material/Fade';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        headingContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        },
        heading: {
            display: 'flex',
            alignItems: 'center',
            gap: '12px'
        },
        title: {
            fontSize: '1.25rem',
            fontWeight: 200,
            textAlign: 'center'
        },
        label: {
            color: '#1F2152',
            fontSize: '1.5rem',
            fontWeight: 600,
            maxWidth: '400px',
            wordWrap: 'break-word'
        },
        line: {
            display: 'block',
            backgroundColor: '#A6A6BF',
            height: 1,
            width: '24px'
        }
    })
);

type SummaryHeadingProps = {
    catchUpItem: CatchUpItems;
    catchUpItemIndex: number;
    catchUpItemsLength: number;
    firstTimeCatchUp: boolean;
    firstTimeCatchUpComplete?: boolean;
    items: CatchUpItems[];
    name: string;
    quickCatchUp: boolean;
    removeItem: boolean;
};

const SummaryHeading: React.FC<SummaryHeadingProps> = ({
    catchUpItem,
    catchUpItemIndex,
    catchUpItemsLength,
    firstTimeCatchUp,
    firstTimeCatchUpComplete,
    items,
    name,
    quickCatchUp,
    removeItem
}) => {
    const classes = useStyles();
    const { type } = catchUpItem;

    const renderLabel = () => {
        switch (type) {
            case 'action_step_due':
                return `How's ${possessiveSuffix(
                    name
                )} towards this action step?`;
            case 'funds_above_25_dollars':
                return `Help your client spend their available balance`;
            case 'no_contact_info':
                return `Add contact info`;
            case 'no_date_of_birth':
                return `Add Date of Birth`;
            case 'no_full_name':
                return `Add Full Name`;
            case 'no_insurance_id':
                return `Missing Insurance ID`;
            case 'no_profile_photo':
                return `Add Profile Picture`;
            case 'no_status_capture_in_last_30_days':
                return `It's been over 30 days since ${possessiveSuffix(
                    name
                )} last status update`;
            case 'no_supplemental_answers':
                return `Share some fun facts about ${name} with their supporters`;
            default:
                return 'Invalid catch up item type';
        }
    };

    const renderFirstTimeMessage = () => {
        switch (type) {
            case 'action_step_due':
                return `Here's an overdue action step we've highlighted for you`;
            default:
                return `Here's an available profile action we've highlighted for you`;
        }
    };

    return (
        <div className={classes.headingContainer}>
            {!firstTimeCatchUp && !firstTimeCatchUpComplete && (
                <div className={classes.heading}>
                    <span className={classes.title}>
                        Catch-up item -{' '}
                        {quickCatchUp
                            ? catchUpItemIndex + 1
                            : catchUpItemsLength - items.length + 1}{' '}
                        of {quickCatchUp ? items.length : catchUpItemsLength}
                    </span>
                    <span className={classes.line} />
                </div>
            )}
            <Fade in={!removeItem} timeout={500}>
                <span
                    className={classes.label}
                    style={firstTimeCatchUp ? { textAlign: 'center' } : {}}
                >
                    {!firstTimeCatchUp
                        ? renderLabel()
                        : renderFirstTimeMessage()}
                </span>
            </Fade>
        </div>
    );
};

export default SummaryHeading;
