import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useHistory, useRouteMatch } from 'react-router';

import {
    DebitCard,
    DebitCardOptions,
    Homeless,
    MemberHistory,
    Needs,
    PexHistory
} from 'types';

import AssignCardPopUp from '../AssignCardPopUp';
import AutoTransferDebit from '../AutoTransferDebit';
import RedemptionCard from '../Redemption/components/RedemptionCard';
import History from '../History';
import PopUp from '../../PopUp';
import { ManageDebit, Redemption } from 'assets/index';

import { fetchMemberPayments } from 'api/payments';
import { useDebitCardActions } from 'hooks/useDebitCardActions';

import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        balanceContainer: {
            display: 'flex',
            gap: '12px',
            color: '#7378E8'
        },
        balance: {
            fontSize: '72px',
            fontWeight: 700,
            lineHeight: '79px'
        },
        available: {
            alignSelf: 'flex-end',
            fontSize: '32px',
            fontWeight: 300,
            lineHeight: '39px',
            paddingBottom: '4px'
        },
        historyContainer: {},
        pexDetails: {
            display: 'flex',
            gap: '32px',
            padding: '0px 8px',
            textAlign: 'center'
        },
        pexNumbers: {
            '& i': {
                marginRight: '4px'
            }
        },
        usageContainer: {},
        usageTitle: {
            color: '#A6A6BF',
            fontSize: '20px',
            fontWeight: 700,
            letterSpacing: '0.39px',
            lineHeight: '27px'
        },
        usageOptions: {
            display: 'flex',
            gap: '16px',
            marginTop: '16px',
            '@media (max-width: 768px)': {
                flexDirection: 'column',
                gap: '12px'
            }
        }
    })
);

type BalanceProps = {
    assignDebitCard: (
        memberID: number,
        type: 'givecard' | 'pex' | 'virtual-givecard',
        last4: number,
        token: string
    ) => void;
    debitCardOptions: DebitCardOptions[];
    debitInfo: DebitCard;
    fullCardHistory: PexHistory[];
    fullMemberHistory: MemberHistory[];
    getDebitCardHistory: (
        id: number,
        type: string,
        full?: boolean
    ) => Promise<any>;
    giveAutoTransfer: boolean;
    hasDebitCard: boolean | undefined;
    holder: Homeless;
    isFetchingDebitHistory: boolean;
    isFetchingDebitInfo: boolean;
    needs: Needs[];
    pexAutoTransfer: boolean;
    recentMemberHistory: MemberHistory[] | null;
    recentCardHistory: PexHistory[];
    setFullCardHistory: (history: PexHistory[]) => void;
    setFullMemberHistory: (memberHistory: MemberHistory[]) => void;
    setRecentCardHistory: (history: PexHistory[]) => void;
    setRecentMemberHistory: (memberHistory: MemberHistory[]) => void;
};

const Balance: React.FC<BalanceProps> = ({
    debitCardOptions,
    debitInfo,
    fullMemberHistory,
    fullCardHistory,
    getDebitCardHistory,
    giveAutoTransfer,
    hasDebitCard,
    holder,
    isFetchingDebitHistory,
    isFetchingDebitInfo,
    pexAutoTransfer,
    needs,
    recentMemberHistory,
    recentCardHistory,
    setFullCardHistory,
    setFullMemberHistory,
    setRecentCardHistory,
    setRecentMemberHistory
}) => {
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();

    const { resetDebitState, success } = useDebitCardActions();

    const { balance, id, organization, photo } = holder;
    const { using_virtual_cards } = organization || false;

    const { available_balance, card_last4, card_type, total_balance } =
        debitInfo || {
            available_balance: null,
            card_last4: null,
            card_type: null
        };

    const [assignPopUp, setAssignPopUp] = useState(false);
    const [debitType, setDebitType] = useState<
        'givecard' | 'pex' | 'virtual-givecard' | null
    >(hasDebitCard ? (card_type !== null ? card_type : 'pex') : null);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [popUp, setPopUp] = useState(false);

    const isGiveCard =
        debitType === 'givecard' || debitType === 'virtual-givecard';

    const handleModalClick = () => {
        setDebitType(null);
        setPopUp(false);
    };

    const handleDebitCardClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Debit Card Clicked',
            label: hasDebitCard ? 'Manage Debit Card' : 'Assign Debit Card'
        });

        if (using_virtual_cards) {
            setAssignPopUp(true);
            return;
        }

        if (card_last4 && debitType) {
            setPopUp(true);
            getDebitCardHistory(id, debitType, false)
                .then((data: any) => {
                    setRecentCardHistory(data.payload);
                })
                .catch((error: any) => {
                    console.error('Error fetching Debit Card history:', error);
                });
        } else {
            if (debitCardOptions.length === 1) {
                setDebitType(debitCardOptions[0].type);
                setPopUp(true);
            } else if (debitCardOptions.length > 1) {
                setAssignPopUp(true);
            } else {
                setDebitType('pex');
                setPopUp(true);
            }
        }
    };

    const handleRedeemCardClick = () => {
        ReactGA.event({
            category: 'Holder Profile',
            action: 'Redeem Card Clicked',
            label: 'Submit Purchase Request'
        });

        history.push(`${match.url}/redeem`);
    };

    const renderDebitDetails = () => {
        if (hasDebitCard) {
            return (
                <div className={classes.pexDetails}>
                    <span className={classes.pexNumbers}>
                        <i className="fal fa-credit-card" />
                        {card_last4}
                    </span>
                    <span>Balance: ${available_balance}</span>
                </div>
            ) as JSX.Element;
        } else {
            return `Member can spend funds immediately and flexibly!`;
        }
    };

    useEffect(() => {
        if (expanded) {
            setLoading(true);

            fetchMemberPayments(id, true)
                .then((res: Response) => res.json())
                .then((data: { payments: MemberHistory[] }) => {
                    setFullMemberHistory(data.payments);
                    setLoading(false);
                })
                .catch((error: any) => {
                    console.error(
                        'Error fetching member balance history:',
                        error
                    );
                    setLoading(false);
                });
        }
    }, [expanded, debitType, id, setFullMemberHistory]);

    useEffect(() => {
        // Fetch member history upon initial render
        if (recentMemberHistory && !recentMemberHistory.length) {
            setLoading(true);

            fetchMemberPayments(id, false)
                .then((res: Response) => res.json())
                .then((data: { payments: MemberHistory[] }) => {
                    setRecentMemberHistory(data.payments);
                    setLoading(false);
                })
                .catch((error: any) => {
                    console.error(
                        'Error fetching member balance history:',
                        error
                    );
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [id, recentMemberHistory, setRecentMemberHistory]);

    useEffect(() => {
        // Fetch member history after a balance change
        if (available_balance !== total_balance) {
            setLoading(true);

            fetchMemberPayments(id, false)
                .then((res: Response) => res.json())
                .then((data: { payments: MemberHistory[] }) => {
                    setRecentMemberHistory(data.payments);
                    setLoading(false);
                })
                .catch((error: any) => {
                    console.error(
                        'Error fetching member balance history:',
                        error
                    );
                    setLoading(false);
                });
        }
    }, [available_balance, id, total_balance, setRecentMemberHistory]);

    useEffect(() => {
        if (
            assignPopUp &&
            debitType !== null 
            // &&
            // !giveAutoTransfer &&
            // !pexAutoTransfer
        ) {
            setAssignPopUp(false);

            setTimeout(() => {
                setPopUp(true);
            }, 150);
        }
    }, [assignPopUp, debitType, giveAutoTransfer, pexAutoTransfer]);

    useEffect(() => {
        if (card_last4 && card_type && !debitType) {
            setDebitType(card_type);
        }
    }, [card_last4, card_type, debitType]);

    useEffect(() => {
        if (popUp && success) {
            resetDebitState();
        }
    }, [popUp, resetDebitState, success]);

    const popUpProps = {
        balance,
        debitCardOptions,
        debitInfo,
        debitType,
        fullCardHistory,
        getDebitCardHistory,
        holder,
        isFetchingDebitHistory,
        isFetchingDebitInfo,
        needs,
        recentCardHistory,
        setDebitType,
        setFullCardHistory,
        setRecentCardHistory,
        setPopUp,
        usingVirtualCards: using_virtual_cards || false
    };

    return (
        <>
            {(hasDebitCard && isGiveCard && giveAutoTransfer) ||
            (hasDebitCard && debitType === 'pex' && pexAutoTransfer) ? (
                <AutoTransferDebit {...popUpProps} />
            ) : (
                <>
                    <div className={classes.balanceContainer}>
                        <span className={classes.balance}>${balance}</span>
                        <span className={classes.available}>available</span>
                    </div>
                    <div className={classes.usageContainer}>
                        <span className={classes.usageTitle}>
                            Spend Available Funds
                        </span>
                        <div className={classes.usageOptions}>
                            <RedemptionCard
                                category={
                                    hasDebitCard
                                        ? 'Manage Debit Card'
                                        : 'Assign Debit Card'
                                }
                                onClick={() => handleDebitCardClick()}
                                thumbnail={ManageDebit}
                                title={renderDebitDetails()}
                            />
                            <RedemptionCard
                                category={'Submit Purchase Request'}
                                onClick={() => handleRedeemCardClick()}
                                thumbnail={Redemption}
                                title={`We'll buy the good or service for you!`}
                            />
                        </div>
                    </div>
                    <History
                        card
                        expanded={expanded}
                        setExpanded={setExpanded}
                        fullHistoryItems={fullMemberHistory}
                        historyItems={recentMemberHistory}
                        loading={loading}
                    />
                    {assignPopUp && (
                        <AssignCardPopUp
                            debitCardOptions={debitCardOptions}
                            handleModalClick={() => setAssignPopUp(false)}
                            holder={holder}
                            photo={photo}
                            setDebitType={setDebitType}
                            usingVirtualCards={using_virtual_cards || false}
                        />
                    )}
                </>
            )}
            {popUp && (
                <PopUp
                    content={'debit card'}
                    handleModalClick={handleModalClick}
                    {...popUpProps}
                />
            )}
        </>
    );
};

export default Balance;
