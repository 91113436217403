import { useRef, useState, useEffect, useCallback } from 'react';
import ReactGA from 'react-ga4';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useSpring, animated } from 'react-spring';
import { useMeasure, useScroll, useWindowSize } from 'react-use';
import store from 'store2';

import { Cities } from 'types';

import introVideo from 'assets/intro.webm';
import samaritanLogo from 'assets/logo.png';
import LoginForm from './LoginForm';
import useSignUp from './useSignUp';

import useLogin from 'hooks/api/useLogin';
import useCities from 'hooks/api/useCities';

import classes from './index.module.scss';

type LoginProps = {
    fetchDebitTypes: () => void;
    resetAllState: () => void;
    resetUnauthorized: () => void;
    setUser: (user: any) => void;
    setMaxBonusAmount: (maxBonusAmount: number) => void;
};

const Login: React.FC<LoginProps> = ({
    fetchDebitTypes,
    resetAllState,
    resetUnauthorized,
    setUser,
    setMaxBonusAmount
}) => {
    const history = useHistory();

    ReactGA.send({ hitType: 'pageview', page: '/login' });

    // api hooks
    const { data } = useCities();
    const { error, isLoading, isSuccess, login, user } = useLogin();
    const signUp = useSignUp();

    // conditional style state
    const [isFormWide, setFormWide] = useState(false);

    // measure window
    const window = useWindowSize();

    // measure the scrolling element
    const [measureRef, measure] = useMeasure();
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const { y } = useScroll(scrollRef);

    // how much vh to scroll before mobile reaches form position
    const scrollLead = 25;
    const percentScroll = Number(((y * 4) / (measure.height || 1)).toFixed(2));

    const videoDivStyle = useSpring({
        transform: `translateY(${-y * 3}px)`,
        opacity: Math.max(0, 1 - percentScroll),
        config: {
            clamp: true
            // precision: 0.001,
        }
    });

    const mobileLogoStyles = useSpring({
        top: `${
            Math.max(scrollLead, (2 - percentScroll * 1.5) * scrollLead) + 2
        }%`,
        config: {
            clamp: true,
            tension: 400
        }
    });

    const LoginFormDivStyles = useSpring({
        width:
            window.width > 767
                ? `max(360px, ${isFormWide ? 120 : 80}%)`
                : 'max(360px, 100%)',
        marginTop:
            window.width > 767
                ? '0vh'
                : `${Math.max(
                      scrollLead,
                      100 - (100 - scrollLead) * percentScroll
                  )}vh`,
        config: {
            clamp: true
        }
    });

    const loginFormDivRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const scrollTimeout = setTimeout(
            () =>
                loginFormDivRef.current?.scrollIntoView({ behavior: 'smooth' }),
            3000
        );

        scrollRef.current?.addEventListener('scroll', () =>
            clearTimeout(scrollTimeout)
        );

        return () => clearTimeout(scrollTimeout);
    }, []);

    // if login success or sign up success
    useEffect(() => {
        if (isSuccess || signUp.isSuccess) {
            fetchDebitTypes();
            resetUnauthorized();
            resetAllState();
            setUser((user as any) || signUp.user);
            setMaxBonusAmount(
                user?.partner?.deployment?.monthly_bonus_pool_max || 40
            );

            const renderFirstTimeCatchUp =
                store.get('renderFirstTimeCatchUp') || {};
            const renderSaveMemberTooltip =
                store.get('renderSaveMemberTooltip') || {};

            if (
                user?.id !== undefined &&
                renderFirstTimeCatchUp[user.id] === undefined
            ) {
                store.set('renderFirstTimeCatchUp', {
                    ...store.get('renderFirstTimeCatchUp'),
                    [user.id]: true
                });
            }

            if (
                user?.id !== undefined &&
                renderSaveMemberTooltip[user.id] === undefined
            ) {
                store.set('renderSaveMemberTooltip', {
                    ...store.get('renderSaveMemberTooltip'),
                    [user.id]: false
                });
            }

            if (history.location.pathname !== '/users/password/new') {
                history.push('/');
            }
        }
    }, [
        fetchDebitTypes,
        history,
        isSuccess,
        resetAllState,
        resetUnauthorized,
        setMaxBonusAmount,
        setUser,
        signUp.isSuccess,
        signUp.user,
        user
    ]);

    const onWide = useCallback(() => setFormWide(true), []);

    return (
        <div className={classes.root}>
            <Helmet title="Login" />
            <animated.div
                className={classes.login}
                ref={(ref) => {
                    scrollRef.current = ref;
                    if (ref) measureRef(ref);
                }}
            >
                <div className={classes.loginDivVideoWrapper}>
                    <animated.div
                        className={classes.loginVideoDiv}
                        style={videoDivStyle}
                    >
                        <div className={classes.opacity} />
                        <animated.video
                            className={classes.loginVideo}
                            autoPlay
                            muted
                            loop
                            src={introVideo}
                        />
                    </animated.div>
                    <div className={classes.logo}>
                        <img src={samaritanLogo} alt="samaritan" height={70} />
                        <div className={classes.logoCaption}>
                            walk with, not by
                        </div>
                    </div>
                </div>
                <animated.div
                    style={mobileLogoStyles}
                    className={classes.mobileLogo}
                >
                    <img src={samaritanLogo} alt="samaritan" height={70} />
                    <div className={classes.logoCaption}>walk with, not by</div>
                </animated.div>
                <animated.div
                    className={classes.loginFormDiv}
                    style={LoginFormDivStyles}
                    ref={loginFormDivRef}
                >
                    <div className={classes.greetingText}>
                        <div className={classes.welcomeBack}>welcome back</div>
                        <div className={classes.partnerSignIn}>
                            partner sign in
                        </div>
                    </div>
                    <LoginForm
                        onSubmit={login}
                        cities={data as Cities[]}
                        onWide={onWide}
                        loading={isLoading || signUp.isLoading}
                        loginError={error}
                        signUpError={signUp.error}
                        onSignUp={signUp.signUp}
                    />
                </animated.div>
            </animated.div>
        </div>
    );
};

export default connect()(Login);
