import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
// TODO: Type reducers
// @ts-ignore
import rootReducer from '../reducers';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['intake'],
    debug: true
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function createStore() {
    const store = configureStore<any>({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware: any) =>
            getDefaultMiddleware({
                serializableCheck: false
            }).concat(thunk),
        devTools: process.env.NODE_ENV === 'development'
    });

    const persistor = persistStore(store);

    persistor.flush();

    return { persistor, store };
}

export type AppStore = ReturnType<typeof createStore>['store'];
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
