import { useMemo } from 'react';

import { Homeless } from 'types';

import MemberDetails from './MemberDetails';
import MemberStatus from './MemberStatus';
import NoRecordFound from './NoRecordFound';
import PopUpButtons from './PopUpButtons';

import { formatDate } from 'util/index';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    body: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    },
    memberDetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        backgroundColor: '#F2F2FD',
        border: '1px solid #E3E3FA',
        borderRadius: '8px',
        marginTop: '16px',
        padding: '24px'
    }
}));

type EligibilityCheckResultsProps = {
    formCheck: {
        dob: string;
        firstName: string;
        insuranceID: string;
        lastName: string;
    };
    handleCloseClick: () => void;
    member: Homeless | null;
    searchTerms: string[];
    setMember: ({
        memberInfo,
        memberFound
    }: {
        memberInfo: Homeless | null;
        memberFound: boolean | null;
    }) => void;
};

const getMemberName = (
    pii_first_name?: string,
    pii_last_name?: string,
    formFirstName?: string,
    formLastName?: string
) => {
    if (pii_first_name || pii_last_name) {
        return `${pii_first_name || ''} ${pii_last_name || ''}`.trim();
    }

    return formFirstName && formLastName
        ? `${formFirstName} ${formLastName}`
        : '-';
};

const getMembershipStatus = (profile_stage: string | undefined) => {
    switch (profile_stage) {
        case 'EXITED':
            return 'CLOSED';
        case 'LIVE':
            return 'ACTIVE';
        case 'NEEDS_REVIEW':
            return 'ACTIVE';
        case 'PENDING':
            return 'PENDING';
        case 'UNSUCCESSFUL_PENDING':
            return 'CLOSED';
        default:
            return '-';
    }
};

const EligibilityCheckResults: React.FC<EligibilityCheckResultsProps> = ({
    formCheck,
    handleCloseClick,
    member,
    searchTerms,
    setMember
}) => {
    const classes = useStyles();

    const {
        date_of_birth,
        id,
        name,
        photo,
        pii_first_name,
        pii_last_name,
        pii_member_id,
        profile_stage
    } = member || {};

    const eligibleMember = useMemo(
        () =>
            member !== null &&
            (profile_stage === 'ELIGIBILITY_LIST' ||
                profile_stage === 'TARGET_LIST'),
        [member, profile_stage]
    );

    const foundMember = useMemo(() => {
        const existingMemberStages = [
            'EXITED',
            'LIVE',
            'NEEDS_REVIEW',
            'PENDING',
            'UNSUCCESSFUL_PENDING'
        ];

        return (
            member !== null &&
            !eligibleMember &&
            existingMemberStages.includes(member.profile_stage)
        );
    }, [eligibleMember, member]);

    const notEligibleMember = useMemo(
        () => member !== null && !foundMember,
        [foundMember, member]
    );

    const memberName = getMemberName(
        pii_first_name,
        pii_last_name,
        formCheck.firstName,
        formCheck.lastName
    );

    const memberDetails = useMemo(
        () => [
            {
                formValue: ['firstName', 'lastName'],
                label: 'Full Name',
                value:
                    !eligibleMember && !foundMember && !notEligibleMember
                        ? `${formCheck.firstName} ${formCheck.lastName}`
                        : memberName
            },
            {
                formValue: ['birthday'],
                label: 'Date of Birth',
                value:
                    !eligibleMember && !foundMember && !notEligibleMember
                        ? formatDate(formCheck.dob.split('T')[0])
                        : date_of_birth
                          ? formatDate(date_of_birth)
                          : '-'
            },
            {
                formValue: ['insuranceID'],
                label: 'Insurance ID',
                value:
                    !eligibleMember && !foundMember && !notEligibleMember
                        ? formCheck.insuranceID
                        : pii_member_id || '-'
            },
            foundMember
                ? {
                      formValue: ['membershipStatus'],
                      label: 'Current Membership Status',
                      value: getMembershipStatus(profile_stage)
                  }
                : null
        ],
        [
            date_of_birth,
            eligibleMember,
            formCheck.dob,
            formCheck.firstName,
            formCheck.insuranceID,
            formCheck.lastName,
            foundMember,
            memberName,
            notEligibleMember,
            pii_member_id,
            profile_stage
        ]
    );

    const filteredDetails = useMemo(
        () =>
            foundMember || eligibleMember || notEligibleMember
                ? memberDetails
                : memberDetails.filter((detail) =>
                      detail?.formValue.some((val) => searchTerms.includes(val))
                  ),
        [
            foundMember,
            eligibleMember,
            memberDetails,
            notEligibleMember,
            searchTerms
        ]
    );

    return (
        <div className={classes.body}>
            {eligibleMember && (
                <MemberStatus name={memberName} type="eligible" />
            )}
            {foundMember && (
                <MemberStatus
                    name={name || ''}
                    photo={photo || ''}
                    type="found"
                />
            )}
            {!eligibleMember && !foundMember && (
                <NoRecordFound
                    name={memberName}
                    notEligibleMember={notEligibleMember}
                />
            )}
            <MemberDetails details={filteredDetails} />
            <PopUpButtons
                eligibleMember={eligibleMember}
                foundMember={foundMember}
                handleCloseClick={handleCloseClick}
                member={member}
                memberID={id}
                setMember={setMember}
            />
        </div>
    );
};

export default EligibilityCheckResults;
