import { useState } from 'react';

import DeploymentSelector from './DeploymentSelector';
import EligibilityForm from './Form';
import Results from './CheckResults';
import Modal2 from 'components/_shared/Modal2';

import { Homeless, User } from 'types';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    body: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '32px'
    }
}));

type EligibilityPopUpProps = {
    handleCloseClick: () => void;
    user: User;
};

const iconStyle = {
    backgroundColor: '#E7EFFE',
    border: '2px solid #062D7A',
    color: '#062D7A',
    fontSize: '24px'
};

const getInitialFormCheck = () => ({
    dob: '',
    firstName: '',
    insuranceID: '',
    lastName: ''
});

const getInitialMemberState = () => ({
    memberInfo: null,
    memberFound: null
});

const getSearchParamsAndValues = (formCheck: {
    [key: string]: string;
}): [string[], string[]] => {
    const trimmedFormCheck = Object.fromEntries(
        Object.entries(formCheck).map(([key, value]) => [key, value.trim()])
    );
    const topFieldSearch =
        trimmedFormCheck.firstName &&
        trimmedFormCheck.lastName &&
        trimmedFormCheck.dob;
    const bottomFieldSearch = trimmedFormCheck.insuranceID;

    if (topFieldSearch && !bottomFieldSearch) {
        return [
            ['birthday', 'firstName', 'lastName'],
            [
                trimmedFormCheck.dob,
                trimmedFormCheck.firstName,
                trimmedFormCheck.lastName
            ]
        ];
    }

    if (!topFieldSearch && bottomFieldSearch) {
        return [['insuranceID'], [trimmedFormCheck.insuranceID]];
    }

    if (topFieldSearch && bottomFieldSearch) {
        return [
            ['birthday', 'firstName', 'lastName', 'insuranceID'],
            [
                trimmedFormCheck.dob,
                trimmedFormCheck.firstName,
                trimmedFormCheck.lastName,
                trimmedFormCheck.insuranceID
            ]
        ];
    }

    return [[], []];
};

const renderForm = ({
    formCheck,
    searchTerms,
    searchValues,
    member,
    setFormCheck,
    setMember,
    handleCloseClick
}: {
    formCheck: {
        dob: string;
        firstName: string;
        insuranceID: string;
        lastName: string;
    };
    searchTerms: string[];
    searchValues: string[];
    member: { memberInfo: Homeless | null; memberFound: boolean | null };
    setFormCheck: React.Dispatch<React.SetStateAction<any>>;
    setMember: React.Dispatch<React.SetStateAction<any>>;
    handleCloseClick: () => void;
}) => {
    if (!member.memberInfo && member.memberFound === null) {
        return (
            <EligibilityForm
                formCheck={formCheck}
                searchTerms={searchTerms}
                searchValues={searchValues}
                setFormCheck={setFormCheck}
                setMember={setMember}
            />
        );
    }
    return (
        <Results
            formCheck={formCheck}
            handleCloseClick={handleCloseClick}
            member={member.memberInfo}
            searchTerms={searchTerms}
            setMember={setMember}
        />
    );
};

const EligibilityPopUp: React.FC<EligibilityPopUpProps> = ({
    handleCloseClick,
    user
}) => {
    const classes = useStyles();

    const deployments = user.partner?.deployments || [];

    const allRequireEligibilityCheck = deployments.every(
        (d) => d.is_eligibility_check_required
    );
    const hasEligibilityCheck = deployments.some(
        (d) => d.is_eligibility_check_required
    );

    const goToDeploymentSelector =
        hasEligibilityCheck && !allRequireEligibilityCheck;

    const [confirmedDeployment, setConfirmedDeployment] = useState<
        null | number
    >(null);
    const [formCheck, setFormCheck] = useState<{
        dob: string;
        firstName: string;
        insuranceID: string;
        lastName: string;
    }>(getInitialFormCheck());
    const [member, setMember] = useState<{
        memberInfo: Homeless | null;
        memberFound: boolean | null;
    }>(getInitialMemberState());

    const [searchTerms, searchValues] = getSearchParamsAndValues(formCheck);

    return (
        <Modal2
            onClose={handleCloseClick}
            additionalStyles={{ iconStyle: iconStyle }}
            iconName={
                !member.memberInfo && member.memberFound === null
                    ? confirmedDeployment
                        ? 'fa-user-check'
                        : 'fa-user-plus'
                    : ''
            }
            title="Eligibility Checker"
        >
            <div className={classes.body}>
                {!confirmedDeployment && goToDeploymentSelector ? (
                    <DeploymentSelector
                        setConfirmedDeployment={setConfirmedDeployment}
                        user={user}
                    />
                ) : (
                    renderForm({
                        formCheck,
                        searchTerms,
                        searchValues,
                        member,
                        setFormCheck,
                        setMember,
                        handleCloseClick
                    })
                )}
            </div>
        </Modal2>
    );
};

export default EligibilityPopUp;
