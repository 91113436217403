import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import store from 'store2';

import { fetchOrganization } from 'api/organization';
import { signUpNewUser } from 'api/user';
import AuthManager from 'auth';

import { setMaxBonusAmount } from 'redux/actions/user';

type SignUpProps = {
    email: string;
    joinCode: string;
    lastName: string;
    name: string;
    orgId: string;
    password: string;
    passwordConfirmation: string;
    phoneNumber: string;
};

const useSignUp = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [user, setUser] = useState(null);

    const signUpFn = useCallback(
        async ({
            email,
            joinCode,
            lastName,
            name,
            orgId,
            password,
            passwordConfirmation,
            phoneNumber
        }: SignUpProps) => {
            setIsLoading(true);
            setError(false);
            const response = await signUpNewUser({
                email,
                password,
                passwordConfirmation,
                name,
                lastName,
                orgId,
                joinCode,
                phoneNumber
            });
            const data = await response.json();

            if (response && response.ok) {
                const auth = AuthManager.getInstance();

                const getOrg = async () => {
                    const orgResponse = await fetchOrganization(
                        parseInt(orgId)
                    );
                    const org = await orgResponse.json();

                    if (org.organization.hasOwnProperty('intake_steps')) {
                        auth.setIntakeSteps(org.organization.intake_steps);
                    } else {
                        auth.setIntakeSteps([]);
                    }
                };

                getOrg();

                auth.setApiToken(data.api_token);
                auth.setToken(data.session_token);
                auth.setUserID(data.id);
                auth.setUserName(`${data.name} ${data.last_name}`);

                if (data.hasOwnProperty('partner')) {
                    auth.setUserDeploymentIds(data.partner.deployment_ids);
                }

                if (data.hasOwnProperty('partner') && data.is_non_profit) {
                    auth.setUserDeploymentIds(data.partner.deployment_ids);
                    auth.setIntakeSteps(data.partner.intake_steps);
                    auth.setUserOrganizationId(data.partner.id);
                    auth.setUserOrganizationName(data.partner.name);

                    if (data.partner.hasOwnProperty('deployment')) {
                        auth.setUserDeploymentId(
                            `${data.partner.deployment.id}`
                        );
                        auth.setUserDeploymentName(
                            data.partner.deployment.name
                        );
                        auth.setDeploymentOrgIds([
                            ...data.partner.deployment.organization_ids
                        ]);

                        dispatch(
                            setMaxBonusAmount(
                                data.partner.deployment
                                    ?.monthly_bonus_pool_max || 40
                            )
                        );
                    } else {
                        auth.setDeploymentOrgIds(data.partner.id);
                    }
                }

                const renderFirstTimeCatchUp =
                    store.get('renderFirstTimeCatchUp') || {};
                const renderSaveMemberTooltip =
                    store.get('renderSaveMemberTooltip') || {};

                if (renderFirstTimeCatchUp[data.id] === undefined) {
                    store.set('renderFirstTimeCatchUp', {
                        ...store.get('renderFirstTimeCatchUp'),
                        [data.id]: true
                    });
                }

                if (renderSaveMemberTooltip[data.id] === undefined) {
                    store.set('renderSaveMemberTooltip', {
                        ...store.get('renderSaveMemberTooltip'),
                        [data.id]: false
                    });
                }

                setIsLoading(false);
                setUser(data);
                setIsSuccess(true);
            } else {
                setIsLoading(false);
                setIsSuccess(false);
                setError(data);
            }
        },
        [dispatch]
    );

    return {
        isSuccess,
        isLoading,
        error,
        user,
        signUp: signUpFn
    };
};

export default useSignUp;
