import { useState } from 'react';

const useSummaryPopUpState = () => {
    const [completeAS, setCompleteAS] = useState(false);
    const [dateISO, setDateISO] = useState('');
    const [debitAssign, setDebitAssign] = useState(false);
    const [debitRedeem, setDebitRedeem] = useState(false);
    const [debitTransfer, setDebitTransfer] = useState(false);
    const [debitType, setDebitType] = useState<
        'givecard' | 'pex' | 'virtual-givecard' | null
    >(null);
    const [editAS, setEditAS] = useState(false);
    const [profilePicture, setProfilePicture] = useState(null);
    const [randomQuestionIndex, setRandomQuestionIndex] = useState(0);
    const [remindAS, setRemindAS] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(true);
    const [updateStatusID, setUpdateStatusID] = useState<null | number>(null);

    // const [actionStepState, setActionStepState] = useState({
    //     completeAS: false,
    //     editAS: false,
    //     remindAS: false
    // });
    // const [debitState, setDebitState] = useState({
    //     debitAssign: false,
    //     debitRedeem: false,
    //     debitTransfer: false,
    //     debitType: null as null | 'givecard' | 'pex'
    // });
    // const [updateStatusState, setUpdateStatusState] = useState({
    //     updateStatus: true,
    //     updateStatusID: null as null | number
    // });

    const resetSummaryPopUpState = () => {
        setCompleteAS(false);
        setDateISO('');
        setDebitAssign(false);
        setDebitRedeem(false);
        setDebitTransfer(false);
        setDebitType(null);
        setEditAS(false);
        setProfilePicture(null);
        setRandomQuestionIndex(0);
        setRemindAS(false);
        setUpdateStatus(true);
        setUpdateStatusID(null);
    };

    return {
        completeAS,
        dateISO,
        debitAssign,
        debitRedeem,
        debitTransfer,
        debitType,
        editAS,
        profilePicture,
        randomQuestionIndex,
        remindAS,
        updateStatus,
        updateStatusID,
        resetSummaryPopUpState,
        setCompleteAS,
        setDateISO,
        setDebitAssign,
        setDebitRedeem,
        setDebitTransfer,
        setDebitType,
        setEditAS,
        setProfilePicture,
        setRandomQuestionIndex,
        setRemindAS,
        setUpdateStatus,
        setUpdateStatusID
    };
};

export default useSummaryPopUpState;
