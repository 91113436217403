import { isValid, parseISO } from 'date-fns';
import { useState } from 'react';

import { Homeless } from 'types';

import LoadingCircle from 'components/_shared/LoadingCircle';
import { CTAButton3 } from 'components/_shared/buttons';

import { searchForMember } from 'api/homelesses';

import { Box, Link, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const useStyles = makeStyles(() => ({
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        marginTop: '40px',
        '& button': {
            padding: '16px 48px',
            width: '100%'
        }
    },
    detailsBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        marginBottom: '16px'
    },
    divider: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        fontSize: '18px',
        fontWeight: 600,
        padding: '16px 0',
        '& hr': {
            border: '1px solid #1F215299',
            flex: 1
        }
    },
    email: {
        color: '#062D7A',
        textDecoration: 'none !important'
    },
    heading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
        paddingTop: '24px',
        '& h2': {
            color: '#062D7A',
            margin: 0
        },
        '& span': {
            color: '#5A6A81',
            fontWeight: 300,
            maxWidth: '75%',
            textAlign: 'center'
        }
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '681px'
    },
    insuranceBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    }
}));

type EligibilityFormProps = {
    formCheck: {
        dob: string;
        firstName: string;
        insuranceID: string;
        lastName: string;
    };
    searchTerms: string[];
    searchValues: string[];
    setFormCheck: (value: {
        dob: string;
        firstName: string;
        insuranceID: string;
        lastName: string;
    }) => void;
    setMember: ({
        memberInfo,
        memberFound
    }: {
        memberInfo: Homeless | null;
        memberFound: boolean;
    }) => void;
};

const EligibilityForm: React.FC<EligibilityFormProps> = ({
    formCheck,
    searchTerms,
    searchValues,
    setFormCheck,
    setMember
}) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const checkIsEligible = async () => {
        try {
            setLoading(true);
            const res = await searchForMember(searchTerms, searchValues);

            const searchResults = Array.isArray(res)
                ? res
                : res.samaritan_members;

            const existingMember = searchResults.find(
                (member: Homeless) =>
                    member.profile_stage !== 'ELIGIBILITY_LIST'
            );

            setTimeout(() => {
                setLoading(false);
            }, 1000);
            
            setMember({
                memberInfo: existingMember ?? searchResults[0] ?? null,
                memberFound: searchResults.length > 0
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const disabledCheck = () => {
        if (formCheck.insuranceID) {
            return false;
        }

        if (formCheck.firstName && formCheck.lastName && formCheck.dob) {
            return false;
        }

        return true;
    };

    const handleDateChange = (date: Date | null) => {
        if (!date || !isValid(date)) {
            return;
        }

        const adjustedDate = new Date(date);
        adjustedDate.setHours(12, 0, 0, 0);

        setFormCheck({
            ...formCheck,
            dob: adjustedDate.toISOString().split('T')[0] || ''
        });
    };

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFormCheck({
            ...formCheck,
            [event.target.name]: event.target.value
        });
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (disabledCheck()) {
            return;
        }

        if (event.key === 'Enter') {
            event.preventDefault();

            checkIsEligible();
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (disabledCheck()) {
            return;
        }

        checkIsEligible();
    };

    const inputStyles = {
        flex: 1,
        width: '100%',
        '& .MuiInputBase-input::placeholder': {
            color: '#1F2152',
            fontStyle: 'italic',
            lineHeight: '24px'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E3E3FA'
            },
            '&:hover fieldset': {
                borderColor: '#B0B0FF'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#999DFF',
                borderWidth: '2px'
            },
            borderRadius: '8px'
        }
    };

    return (
        <>
            {loading ? (
                <div className={classes.loadingContainer}>
                    <LoadingCircle />
                </div>
            ) : (
                <>
                    <div className={classes.heading}>
                        <h2>Verify Member Eligibility</h2>
                        <span>
                            Provide the member's personal details or Insurance
                            ID to check their eligibility
                        </span>
                    </div>
                    <div className={classes.detailsBody}>
                        <span>Full Name</span>
                        <Box
                            onSubmit={(e) => handleSubmit(e)}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '16px'
                            }}
                        >
                            <TextField
                                name="firstName"
                                onChange={(e) => handleInputChange(e)}
                                onKeyDown={handleKeyDown}
                                placeholder="First Name"
                                sx={inputStyles}
                                value={formCheck.firstName}
                                variant="outlined"
                            />
                            <TextField
                                name="lastName"
                                onChange={(e) => handleInputChange(e)}
                                onKeyDown={handleKeyDown}
                                placeholder="Last Name"
                                sx={inputStyles}
                                value={formCheck.lastName}
                                variant="outlined"
                            />
                        </Box>
                        <span>Date of Birth</span>
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    disableFuture
                                    name="dob"
                                    onChange={handleDateChange}
                                    referenceDate={new Date('1995-01-02')}
                                    slotProps={{
                                        textField: {
                                            onKeyDown: handleKeyDown,
                                            placeholder: 'January 1, 1995'
                                        }
                                    }}
                                    sx={inputStyles}
                                    value={
                                        formCheck.dob
                                            ? parseISO(formCheck.dob)
                                            : null
                                    }
                                />
                            </LocalizationProvider>
                        </Box>
                    </div>
                    <div className={classes.divider}>
                        <hr />
                        <span>or</span>
                        <hr />
                    </div>
                    <div className={classes.insuranceBody}>
                        <span>Insurance ID</span>
                        <Box component="form" noValidate autoComplete="off">
                            <TextField
                                fullWidth
                                name="insuranceID"
                                onChange={(e) => handleInputChange(e)}
                                onKeyDown={handleKeyDown}
                                onSubmit={(e) => handleSubmit(e)}
                                placeholder="xx-xxxx"
                                sx={inputStyles}
                                value={formCheck.insuranceID}
                                variant="outlined"
                            />
                        </Box>
                    </div>
                    <div className={classes.buttonContainer}>
                        <CTAButton3
                            disabled={disabledCheck()}
                            onClick={checkIsEligible}
                        >
                            Verify Eligibility
                        </CTAButton3>
                        <span style={{ fontSize: '14px' }}>
                            Need assistance?{' '}
                            <Link
                                color="#7C78DE"
                                href="mailto:support@samaritan.city"
                                target="_blank"
                                rel="noreferrer"
                                underline="none"
                            >
                                Contact Support
                            </Link>
                        </span>
                    </div>
                </>
            )}
        </>
    );
};

export default EligibilityForm;
