import AssignBody from './components/AssignBody';
import Modal2 from 'components/_shared/Modal2';
import VirtualCard from 'components/_shared/PopUp2/components/VirtualCard';

import { DebitCardOptions, Homeless } from 'types';

type AssignCardPopUpProps = {
    debitCardOptions: DebitCardOptions[];
    handleModalClick: () => void;
    holder: Homeless;
    intakeEmail?: string;
    photo: string;
    setAssignPopUp?: (assignView: boolean) => void;
    setDebitType: (
        type: 'givecard' | 'pex' | 'virtual-givecard' | null
    ) => void;
    setReplaceView?: (replaceView: boolean) => void;
    usingVirtualCards: boolean;
};

const AssignCardPopUp: React.FC<AssignCardPopUpProps> = ({
    debitCardOptions,
    handleModalClick,
    holder,
    intakeEmail,
    photo,
    setAssignPopUp,
    setDebitType,
    setReplaceView,
    usingVirtualCards
}) => {
    const { card, email, id, name } = holder;

    const handleUpdateModalClose = () => {
        handleModalClick();
    };

    return (
        <>
            {!usingVirtualCards || card?.card_type === 'virtual-givecard' ? (
                <Modal2 onClose={handleUpdateModalClose} title="Assign Card">
                    <AssignBody
                        debitCardOptions={debitCardOptions}
                        photo={photo}
                        setAssignPopUp={setAssignPopUp}
                        setDebitType={setDebitType}
                        setReplaceView={setReplaceView}
                    />
                </Modal2>
            ) : (
                <VirtualCard
                    email={email ?? intakeEmail!}
                    handleClose={handleUpdateModalClose}
                    id={id}
                    name={name}
                />
            )}
        </>
    );
};

export default AssignCardPopUp;
